<nav class="navbar navbar-expand-lg navbar-light border-bottom" style="position: fixed;width: 100%;">
    <div class="container-fluid" id="main_nav_wrapper">
        <div class="top_nav">
            <div>
                <button class="btn" id="sidebarToggle">
                    <svg xmlns="http://www.w3.org/2000/svg" id="Outline" id="toggle-svg" viewBox="0 0 24 24" width="20"
                        height="20" fill="#000">
                        <rect y="11" width="24" height="2" rx="1" />
                        <rect y="4" width="24" height="2" rx="1" />
                        <rect y="18" width="24" height="2" rx="1" />
                    </svg>
                </button>
            </div>
        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div class="dropdown" tabindex="1">
                <i class="db2" tabindex="1"></i>
                <a class="dropbtn">
                    <img src="assets/icons/Group 102.png" alt="" class="profile_img_one">
                    &nbsp;
                    <span class="mb-view-hide">{{userDetails?.name}}</span>
                </a>
                <div class="dropdown-content">
                    <a [routerLink]="['home/profile']">
                        <img src="assets/icons/dashboard-icons/user.svg" id="profle_icon">&nbsp; My profile
                    </a>
                    <a (click)="gotoChangePasswordPage()">
                        <img src="assets/icons/dashboard-icons/lock.svg" id="key_icon">&nbsp; Change Password
                    </a>
                    <a (click)="logout()">
                        <img src="assets/icons/dashboard-icons/log-in.svg" id="log_icon">&nbsp; Log Out
                    </a>
                </div>
            </div>
        </div>
    </div>
</nav>