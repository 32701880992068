import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { RouteConstants } from 'src/app/shared/route-constants';

@Component({
  selector: 'app-completed-services',
  templateUrl: './completed-services.component.html',
  styleUrls: ['./completed-services.component.scss']
})
export class CompletedServicesComponent implements OnInit {

  lang = [
    { name: "HTML" },
    { name: "ReactJS" },
    { name: "Angular" },
    { name: "Bootstrap" },
    { name: "PrimeNG" },
  ];
  
  serviceList: any;
  branchId: any = JSON.parse(<string>this.storageService.getItem("branchId"));

  constructor(public dataService: DataService, public router: Router, public storageService: StorageService) { }


  ngOnInit(): void {
    this.getServiceRequestList();
  }

  getServiceRequestList = () => {
    const reqObj = {
      branchId: this.branchId,
      status: 'Completed'
    }
    this.dataService.getServiceRequestList(reqObj).subscribe((res: any) => {
      if (res && res.error === undefined) {
        this.serviceList = res.results;
      }
    });
  }

  gotoServiceDetails = (cObj?: any) => {
    this.router.navigate([`${RouteConstants.HOME_PAGE+ '/' +RouteConstants.PLANNER + '/' + RouteConstants.SERVICEDETAILS}`], {
      queryParams: {
        sr_id: cObj?.service_request_id
      }
    })
  }
}
