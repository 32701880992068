<div class="container" id="main-container">
    <!-- <div class="total-branch">
        <div class="branch-icon">
            <img src="/assets/icons/arrow-small-left.svg" alt="">
        </div> -->
        <div class="branch-time">
            <p>Branch Timing</p>
        </div>
    <!-- </div> -->

 <!------------------->
    <h1>SET TIMING</h1>
    <h3>Pick your branch timing to show  it to your customer</h3>


<div class="frst-branch">

    <div class="toogle-side">

        <div class="days">
            <p>Monday</p>
        </div>
        
        <div class="toggle">
            <label class="switch">
                <input type="checkbox" checked>
                <span class="slider round"></span>
            </label>
        </div>

    </div> 
     
<!-------------------->
 <div class="timmer">
    <div class="from-time">
        <p>From</p>
    </div>
    <div class="time-log">
        <timepicker [(ngModel)]="mytime"></timepicker> 
    </div>
     <div class="from-time">
        <p>To</p>
    </div>
    <div class="time-log">
        <timepicker [(ngModel)]="mytime"></timepicker> 
    </div>
</div> 
</div>
<!----------------------->

<div class="frst-branch mt-5">

    <div class="toogle-side">

        <div class="days">
            <p>Tuesday</p>
        </div>
        
        <div class="toggle">
            <label class="switch">
                <input type="checkbox" checked>
                <span class="slider round"></span>
            </label>
        </div>

    </div> 
     
<!-------------------->
 <div class="timmer">
    <div class="from-time">
        <p>From</p>
    </div>
    <div class="time-log">
        <timepicker [(ngModel)]="mytime"></timepicker> 
    </div>
     <div class="from-time">
        <p>To</p>
    </div>
    <div class="time-log">
        <timepicker [(ngModel)]="mytime"></timepicker> 
    </div>
</div> 
</div>
<!------------------------->
<div class="frst-branch mt-5">

    <div class="toogle-side">

        <div class="days">
            <p>Wednesday</p>
        </div>
        
        <div class="toggle">
            <label class="switch">
                <input type="checkbox" checked>
                <span class="slider round"></span>
            </label>
        </div>

    </div> 
     
<!-------------------->
 <div class="timmer">
    <div class="from-time">
        <p>From</p>
    </div>
    <div class="time-log">
        <timepicker [(ngModel)]="mytime"></timepicker> 
    </div>
     <div class="from-time">
        <p>To</p>
    </div>
    <div class="time-log">
        <timepicker [(ngModel)]="mytime"></timepicker> 
    </div>
</div> 
</div>
<!------------------------->
<div class="frst-branch mt-5">

    <div class="toogle-side">

        <div class="days">
            <p>Thrusday</p>
        </div>
        
        <div class="toggle">
            <label class="switch">
                <input type="checkbox" checked>
                <span class="slider round"></span>
            </label>
        </div>

    </div> 
     
<!-------------------->
 <div class="timmer">
    <div class="from-time">
        <p>From</p>
    </div>
    <div class="time-log">
        <timepicker [(ngModel)]="mytime"></timepicker> 
    </div>
     <div class="from-time">
        <p>To</p>
    </div>
    <div class="time-log">
        <timepicker [(ngModel)]="mytime"></timepicker> 
    </div>
</div> 
</div>
<!------------------------->
<div class="frst-branch mt-5">

    <div class="toogle-side">

        <div class="days">
            <p>Friday</p>
        </div>
        
        <div class="toggle">
            <label class="switch">
                <input type="checkbox" checked>
                <span class="slider round"></span>
            </label>
        </div>

    </div> 
     
<!-------------------->
 <div class="timmer">
    <div class="from-time">
        <p>From</p>
    </div>
    <div class="time-log ">
        <timepicker [(ngModel)]="mytime"></timepicker> 
    </div>
     <div class="from-time">
        <p>To</p>
    </div>
    <div class="time-log">
        <timepicker [(ngModel)]="mytime"></timepicker> 
    </div>
</div> 
</div>
<!------------------------->
<div class="frst-branch mt-5">

    <div class="toogle-side">

        <div class="days">
            <p>Saturday</p>
        </div>
        
        <div class="toggle">
            <label class="switch">
                <input type="checkbox" checked>
                <span class="slider round"></span>
            </label>
        </div>

    </div> 
     
<!-------------------->
 <div class="timmer">
    <div class="from-time">
        <p>From</p>
    </div>
    <div class="time-log">
        <timepicker [(ngModel)]="mytime"></timepicker> 
    </div>
     <div class="from-time">
        <p>To</p>
    </div>
    <div class="time-log">
        <timepicker [(ngModel)]="mytime"></timepicker> 
    </div>
</div> 
</div>
<!------------------------->
<div class="frst-branch mt-5">

    <div class="toogle-side">

        <div class="days">
            <p>Sunday</p>
        </div>
        
        <div class="toggle">
            <label class="switch">
                <input type="checkbox" checked>
                <span class="slider round"></span>
            </label>
        </div>

    </div> 
     
<!-------------------->
 <div class="timmer">
    <div class="from-time">
        <p>From</p>
    </div>
    <div class="time-log">
        <timepicker [(ngModel)]="mytime"></timepicker> 
    </div>
     <div class="from-time">
        <p>To</p>
    </div>
    <div class="time-log">
        <timepicker [(ngModel)]="mytime"></timepicker> 
    </div>
</div> 
</div>
<!------------------------->
<button type="button" class="btn btn-save mt-3">Save </button>
<!--------------->
</div>
  
    
  
