import { DOCUMENT } from "@angular/common"

export class RouteConstants {
  static HOME_PAGE = "home"
  static PLANNER = 'planner'
  static DOCUMENTS = 'documents'
  static CREATESERVICEREQUEST = 'create-service-request'
  static SIGNIN = 'sign-in'
  static FORGOTPIN = 'forgot-pin'
  static PROFILE = 'profile'
  static ADD_MANAGER = 'add-manager'
  static MANAGE_BRANCHES = 'manage-branches'
  static SERVICES  = 'services'
  static CLONE_SERVICES  = 'clone-services'
  static BRANCHSHEDULE = 'branch-shedule'
  static ADD_BRANCH = 'add-branch'
  
  static CREATESERVICE='create-service'
  static ASSIGNJOB='assign-job'
  static CREATESTAFF='create-staff'
  static STAFFLIST='staff-list'
  static STAFF = 'staff'
  static COMPLETEDSERVICES = 'completed-services'
  static CREATENEWSERVICE = 'create-new-service'
  static VIEWSEVICES = 'view-services'
  static EDIT_BRANCH = 'edit-branch'
  static USER = 'user'
  static CREATEUSER ='create-user'
  static USERLIST = 'user-list'
  static USERVIEW = 'user-view'
  static MANAGEINQUIRIES = 'manage-inquiries'
  static INQUIRIESLIST = 'inquiries'
  static CUSTOMERINVOICE = 'customerinvoice'
  static CUSTOMERS = 'customers'
  static CUSTOMERSLIST = 'customers-list'
  static CREATECUSTOMER = 'create-customer'
  static EXPENSESLIST ='expenses-list'
  static EXPENSES ='expenses'
  static RECORDEXPENSES ='record-expenses'
  static CREATE_SERVICE_CATEGORY = 'create-category'
  static MANAGECATEGOTY = 'manage-category'
  static CREATECATEGORY = 'create-category'
  static CATEGORYLIST = 'category-list'
  static MANAGESERVICE ='manage-service'
  static SERVICEDETAILS='service-details'
  static SERVICESLIST ='services-list'
  static MANAGECREATESERVICE ='create-service'
  static CUSTOMERINVOICEDETAILS='customer-invoice-details'
  static SALESREPORTS='sales-reports'
  static INPROGRESSSERVICES='in-progress'
  static NEWREQUEST ='new-request'
  static INQUIRIESDETAILS ="inquiries-details"
  static SERVICEREQUESTHISTORY = 'service-request-history'
  static DASHBOARD = 'dashboard'
  static SUBSCRIPTION ='subscription'
  static REGISTERYOURBUSINESS = 'register-your-business'
  static RENEWALCARDSLIST ='renewal-cards-list'
  static CHANGEPASSWORD ='change-password'
  static UPDATEPASSWORD ='update-password'
  static REPORTS = 'reports'
   static SETTINGS='settings'
   static PROFILE_DOCUMENT='profile-document'
   static PRICING_PAGE='pricing-page'
   static BRANCH_MANAGERS='profile/branch-managers'
   static TOTAL_STAFF ='total-staff'
   static BRANCH_SELECTION ='branch-selection'
   static MYJOBS = 'my-jobs'
   static CREATEBM='create-bm'
   static BMLIST='bm-list'
   static VIEWJOBS = 'view-jobs'
   static JOBSHISTORY = 'booking-history'
   static MANAGE_INVENTORY = 'manage-inventory'
   static POINTOFSALE = 'point-of-sale'
   static ADDRESSLIST = 'address-list'
   static PLAN = 'plan'
   static PLANLIST ='plan-list'
   static CREATEPLAN = 'create-plan'
   static PAYMENT_SUCCESS = "payment-success"
    static PAYMENT_FAIL = "payment-fail"
}
