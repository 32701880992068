import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePWDForm!: any;
  formSubmited: boolean = false;
  value!: string;
  isPasswordVisibleNew = false;
  isPasswordVisibleCnfrm = false;
  isPasswordVisibleCurrent = false;


  matchingControlName = String;
  AC: any;
  password: any;
  confirmpassword: any;
  constructor(private formBuilder: FormBuilder, public dataService: DataService, public router: Router) {
    this.changePWDForm = new FormGroup({
      currentPWD: new FormControl('', [Validators.required]),
      newPWD: new FormControl('', [Validators.required]),
      cnfrmPWD: new FormControl('', [Validators.required, this.passwordsShouldMatch.bind(this)]),
    })
  }
  get pwdControls() { return this.changePWDForm.controls; }

  private passwordsShouldMatch(fGroup: FormGroup) {
    return this?.changePWDForm?.get('newPWD')?.value === this.changePWDForm?.get('cnfrmPWD')?.value
      ? null : { 'mismatch': true };
  }
  ngOnInit(): void {
  }

  // onSubmit = (event: any) => {
  //   event.preventDefault();
  //   console.log(this.changePWDForm.value)
  //   console.log(this.pwdControls)
  //   this.formSubmited = true;
  //   if (this.changePWDForm.valid) {

  //     const reqObj = {
  //       current_password: this.changePWDForm.value.currentPWD,
  //       new_password: this.changePWDForm.value.newPWD,
  //     }
  //     this.dataService.changePassword(reqObj).subscribe((res: any) => {
  //       if (res) {
  //         Swal.fire({
  //           title: 'Password Changed',
  //           text: 'Please log in with the new password',
  //           icon: 'success',
  //           confirmButtonText: 'OK',
  //           allowOutsideClick: false,
  //           allowEscapeKey: false
  //         }).then((res: any) => {
  //           if (res.isConfirmed) {
  //             this.changePWDForm.reset();
  //             this.formSubmited = false;
  //           }
  //         })

  //       }
  //     });
  //   }
  // }
  onSubmit = (event: any) => {
    event.preventDefault();
    console.log(this.changePWDForm.value);
    console.log(this.pwdControls);
    this.formSubmited = true;
    if (this.changePWDForm.valid) {
      const reqObj = {
        current_password: this.changePWDForm.value.currentPWD,
        new_password: this.changePWDForm.value.newPWD,
      };
      this.dataService.changePassword(reqObj).subscribe((res: any) => {
        if (res) {
          Swal.fire({
            title: 'Password Changed',
            text: 'Please log in with the new password',
            icon: 'success',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
              this.changePWDForm.reset();
              this.formSubmited = false;
              // Redirect to the login screen
              this.router.navigate(['/sign-in']);
            }
          });
        }
      });
    }
  };


  togglePasswordVisibility(field: 'new' | 'cnfrm' | 'current') {
    if (field === 'new') {
      this.isPasswordVisibleNew = !this.isPasswordVisibleNew;
    } else if (field === 'cnfrm') {
      this.isPasswordVisibleCnfrm = !this.isPasswordVisibleCnfrm;
    } else if (field === 'current') {
      this.isPasswordVisibleCurrent = !this.isPasswordVisibleCurrent;
    }
  }

}
