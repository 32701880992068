export const environment = {
  production: true,
  apiUrl: 'https://api.scheduleitnow.co.uk/',  
  pinCodeLabelName :'Post Code',
  countryCode: '+44',
  mediaApiurl: 'https://sgp1.digitaloceanspaces.com/servu/images/',
  currencyCode:'GBP',
  countryList: 'United Kingdom',
  loadersec : 2000,
  currencySymbol : '£',
  siteLogo: '/assets/images/sit.png',
  productName: 'ScheduleItNow.',
  paymentMode : 'stripe',
  googleAPIKey: "AIzaSyCDD-yuztbp1BhkGQHzpiTbxHS8Ve1H7WE", // G-Maps
};
