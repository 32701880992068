<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
	<div class="form-group">
		<label for="name">Email</label>
		<input type="text" class="form-control" formControlName="email">
		<div *ngIf="submitted && registerForm.get('email')errors" class="alert alert-danger">
			<div *ngIf="registerForm.get('email')errors('required')">Email is required</div>
		</div>
	</div>

	<div class="form-group">
		<label for="name">Password</label>
		<input type="password" class="form-control" formControlName="password">
		<div *ngIf="submitted && registerForm.get('password')errors" class="alert alert-danger">
			<div *ngIf="registerForm.get('password')errors('required')">Password is required</div>
		</div>
	</div>

	<div class="form-group">
		<label for="name">Confirm Password</label>
		<input type="password" class="form-control" formControlName="confirmPassword">
		<div *ngIf="submitted && registerForm.get('confirmPassword')errors" class="alert alert-danger">
			<div *ngIf="registerForm.get('confirmPassword')errors('required')">Confirm Password is required</div>

			<div *ngIf="registerForm.get('confirmPassword')errors('confirmPasswordValidator')">Passsword and
				Confirm Password didn't match. </div>
		</div>
	</div>
	<button type="submit" class="btn btn-success">Submit</button>
</form>