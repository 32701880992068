import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loaderService: LoaderService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    console.log(request)
    console.log(request.method)
    if(request.method === 'GET'){
      this.loaderService.show();
     }

     return next.handle(request).pipe(
           finalize(() => setTimeout(() => {
            this.loaderService.hide()
           }, environment.loadersec)),
     );
  }
} 