import { Component, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { ViewChild } from '@angular/core';
import 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-sales-reports',
  templateUrl: './sales-reports.component.html',
  styleUrls: ['./sales-reports.component.scss']
})
export class SalesReportsComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 10
      }
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [
    DataLabelsPlugin
  ];

  public barChartData: ChartData<'bar'> = {
    labels: [ 'Jan', 'Feb', 'Mar',  ],
    datasets: [
      { data: [ 16, 12, 17, 21, 14, 24, 18 ], label: 'Revenue' },
      { data: [ 65, 83, 97, 62, 24, 72, 58 ], label: 'Expenses' },
    ]
  };

  public ChartData: ChartData<'bar'> = {
    labels: [ 'Jan', 'Feb', 'Mar',],

    datasets: [
  
      { data: [ 1600, 1200, 500 ], label: 'Refund' },
      // { data: [ 6500, 8300, 9700, 6200, 2400, 7200, 5800 ], label: 'Profit' },
    ]
  };
  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    console.log(event, active);
  }

  public randomize(): void {
    // Only Change 3 values
    this.barChartData.datasets[0].data = [
      Math.round(Math.random() * 100),
      59,
      80,
      Math.round(Math.random() * 100),
      56,
      Math.round(Math.random() * 100),
      40 ];

    this.chart?.update();
  }

/********PIE CHART */
public pieChartOptions: ChartConfiguration['options'] = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'top',
 
    },
   
  }
};
public pieChartType: ChartType = 'pie';
  public pieChartPlugins = [ ];




public pieChartData: ChartData<'pie', number[], string | string[]> = {
  labels: [ 'Revenue','Expenses' ],
  // labels: [ [ 'Profit', 'Loss' ] ],
  datasets: [
    { data: [ 1600, 1800 ], label: 'Loss' },
    { data: [  7200, 5800 ], label: 'Profit' },
  ]
};


// events


changeLabels(): void {
  const words = [ 'hen', 'variable', 'embryo', 'instal', 'pleasant', 'physical', 'bomber', 'army', 'add', 'film',
    'conductor', 'comfortable', 'flourish', 'establish', 'circumstance', 'chimney', 'crack', 'hall', 'energy',
    'treat', 'window', 'shareholder', 'division', 'disk', 'temptation', 'chord', 'left', 'hospital', 'beef',
    'patrol', 'satisfied', 'academy', 'acceptance', 'ivory', 'aquarium', 'building', 'store', 'replace', 'language',
    'redeem', 'honest', 'intention', 'silk', 'opera', 'sleep', 'innocent', 'ignore', 'suite', 'applaud', 'funny' ];
  const randomWord = () => words[Math.trunc(Math.random() * words.length)];
  this.pieChartData.labels = new Array(2).map(_ => randomWord());
    



  this.chart?.update();
}



  constructor() { }

  ngOnInit(): void {
  }

}
