<!-- <div class="container cp-wrapper" id="main-page-wrapper">
    <h6 class="page-heading">Change Password</h6>
    <form (ngSubmit)="onSubmit($event)" [formGroup]="changePWDForm">
        <div>
            <label for="" class="mt-4 pb-3">Current Password</label><br>
            <p-password [feedback]="false" class="pwd-input-box" formControlName="currentPWD"></p-password>
            <div class="err_msg" *ngIf="pwdControls.currentPWD.errors?.required  && formSubmited">Current Password required</div>
        </div>

        <div>
            <label for="" class="mt-4 pb-3">New Password</label><br>
            <p-password formControlName="newPWD" [toggleMask]="true"></p-password>
            <div class="err_msg" *ngIf="pwdControls.newPWD?.errors?.required  && formSubmited">New Password required</div>
            <div class="err_msg" *ngIf="pwdControls.newPWD?.errors?.minlength  && formSubmited">New Password should be atleast 6</div>
        </div>
        
        <div>
            <label for="" class="mt-3 pb-3">Confirm New Password</label><br>
            <p-password formControlName="cnfrmPWD" [toggleMask]="true"></p-password>
            <div class="err_msg" *ngIf="pwdControls.cnfrmPWD.errors?.required  && formSubmited">Confirm New Password required</div>
            <div class="err_msg" *ngIf="pwdControls.cnfrmPWD.errors?.mismatch  && formSubmited">Password and confirm not match</div>
        </div>
        <button type="submit" class="btn btn-save mt-3">Change Password</button>
    </form>
</div> -->



<div class="container cp-wrapper" id="main-page-wrapper">
    <!-- <h6 class="page-heading">Change Password</h6> -->

    <div class="page-titles">
        <h3 class="page-title">Change Password</h3>
    </div>


    <div class="card">
        <form (ngSubmit)="onSubmit($event)" [formGroup]="changePWDForm">
            <!-- <div>
                <label for="" class="mt-4 pb-3"id="form-label-color">Current Password <span class="manstar"> *</span> </label><br>
                <p-password [feedback]="false" class="pwd-input-box" formControlName="currentPWD"></p-password>
                <div class="err_msg" *ngIf="pwdControls.currentPWD.errors?.required  && formSubmited">Current Password
                    required</div>
            </div> -->

            <div class="cp-inner-wrapper">
                <label for="" class="mt-4 pb-3" id="form-label-color">Current Password <span
                        class="manstar">*</span></label><br>
                <div class="input-with-icon">
                    <input type="{{ isPasswordVisibleCurrent ? 'text' : 'password' }}" formControlName="currentPWD">
                    <i class="ng-tns-c126-1 pi"
                        [ngClass]="{'pi-eye': !isPasswordVisibleCurrent, 'pi-eye-slash': isPasswordVisibleCurrent}"
                        (click)="togglePasswordVisibility('current')"></i>
                </div>
                <div class="err_msg" *ngIf="pwdControls.currentPWD.errors?.required  && formSubmited">Current Password
                    required</div>
            </div>


            <div class="cp-inner-wrapper">
                <label for="" class="mt-4 pb-3" id="form-label-color">New Password <span class="manstar">
                        *</span></label><br>
                <div class="input-with-icon">
                    <input type="{{ isPasswordVisibleNew ? 'text' : 'password' }}" formControlName="newPWD">
                    <i class="ng-tns-c126-1 pi"
                        [ngClass]="{'pi-eye': !isPasswordVisibleNew, 'pi-eye-slash': isPasswordVisibleNew}"
                        (click)="togglePasswordVisibility('new')"></i>
                </div>
                <div class="err_msg" *ngIf="pwdControls.newPWD?.errors?.required && formSubmited">New Password required
                </div>
                <div class="err_msg" *ngIf="pwdControls.newPWD?.errors?.minlength && formSubmited">New Password should
                    be at
                    least 6</div>
            </div>

            <div class="cp-inner-wrapper">
                <label for="" class="mt-3 pb-3" id="form-label-color">Confirm New Password <span class="manstar">
                        *</span></label><br>
                <div class="input-with-icon">
                    <input type="{{ isPasswordVisibleCnfrm ? 'text' : 'password' }}" formControlName="cnfrmPWD">
                    <i class="ng-tns-c126-1 pi"
                        [ngClass]="{'pi-eye': !isPasswordVisibleCnfrm, 'pi-eye-slash': isPasswordVisibleCnfrm}"
                        (click)="togglePasswordVisibility('cnfrm')"></i>
                </div>
                <div class="err_msg" *ngIf="pwdControls.cnfrmPWD.errors?.required && formSubmited">Confirm New Password
                    required</div>
                <div class="err_msg" *ngIf="pwdControls.cnfrmPWD.errors?.mismatch && formSubmited">Password and confirm
                    not
                    match</div>
            </div>

            <button type="submit" class="btn btn-save mt-3" id="cp-btn">Change Password</button>
        </form>
    </div>
</div>