import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { of, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map, tap } from "rxjs/operators";
// import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { StorageService } from '../services/storage.service';
import { RouteConstants } from "../shared/route-constants";

@Injectable(
  {
    providedIn: 'root'
  }
)
export class AuthService {
  userInfo: any;
  // private tokenSubject: BehaviorSubject<UserLoginResponse>;
  public onUserLogin = new Subject();

  constructor(
    private httpService: HttpClient,
    // private cookieService: CookieService,
    private storageService: StorageService,
    private router: Router
  ) {

  }

  //Method to call RestAPI for login and generate access and refresh tokens
  signIn = (reqObj: any) => {
    // return new Promise((resolve, reject) => {
    const params = {
      username: reqObj.email,
      password: reqObj.pwd
    }
    //login Url
    let targetUrl = environment.apiUrl + `token/`;
    return this.httpService.post(targetUrl, params).pipe(

      map((resp: any) => {
        // console.log('auth service: login:', resp);
        //This conditions is using for showing and hiding nav-bars based on roles
        if (typeof (resp.customer_id != undefined) && resp.customer_id != null) {
          resp.role = "CUSTOMER";
        }
        if (typeof (resp.service_provider_id != undefined) && resp.service_provider_id != null) {
          resp.role = "SERVICE_PROVIDER";
        }
        if (typeof (resp.employee_id != undefined) && resp.employee_id != null) {
          if (resp.emp_or_user_role == "serviceattender") {
            resp.role = "EMPLOYEE";
            //this.userInfo.role = "EMPLOYEE"
          } else if (resp.emp_or_user_role == "backofficeteam") {
            resp.role = "USER";
            //this.userInfo.role = "USER"
          }
        }
        if (typeof (resp.employee_id != undefined) && resp.employee_id != null) {
          if (resp.emp_or_user_role == "backofficeteam") {
            resp.role = "USER";
            //this.userInfo.role = "EMPLOYEE"
          }
        }

        if (resp.customer_id == undefined && resp.service_provider_id == undefined && resp.employee_id == undefined) {
          resp.role = "SUPERADMIN";
        }

        console.log("login resp", resp);
        if(resp.branches?.length === 1){
            this.storageService.setItem("branchId", JSON.stringify(resp.branches[0].branch_id));
        }
        if(resp.role === "Staff" || resp.role === "Admin"){
          this.storageService.setItem("branchId", JSON.stringify(resp.branch_id));
          this.storageService.setItem("branch_slug_name", JSON.stringify(resp.branch_slug_name));
        }

        this.storageService.setItem("role", JSON.stringify(resp.role));
        this.storageService.setItem("user_info", JSON.stringify(resp));

        this.userInfo = JSON.parse(<string>this.storageService.getItem("user_info"));
        this.onUserLogin.next(this.userInfo);
        return resp;
      }),
      catchError(this.handleError)
      
      )
      // });
  }


  //Method stands for Change Password
  forgotPwd = (reqObj: any) => {
    const params = {
      email: reqObj.email
    }
    let targetUrl = environment.apiUrl + `auth/passwordrecovery`;
    return this.httpService.put<[]>(targetUrl, params).pipe(
      map(response => {
        return response
      }),
      catchError(this.handleError)
    );
  }

  logout() {
    this.userInfo = null;
    this.isAuthenticated();
    this.storageService.clearAll();
    this.onUserLogin.next(null);
    this.router.navigate([RouteConstants.SIGNIN]);
  }

  //Method is used to identify user is logged-in or not
  isAuthenticated() {
    return this.storageService.isAuthenticated();
  }


  
  handleError(error: any) {
    console.log(error);
    let errorMessage: any;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = {
        errorCode: `${error.error.status_code}`,
        message: `${error.error.message || error.error.Error}`
      }

    }
    console.log(errorMessage);
    return of(errorMessage);
  }
}


