import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  menuList: any;
  productName = environment.productName;
  siteLogo = environment.siteLogo;
  constructor(public authService: AuthService, public storageService: StorageService, private router: Router) { }
  role: any = JSON.parse(<string>this.storageService.getItem("user_info"))?.role;
  currentRoute: any;
  menus = ['planner', 'manage_branches', 'manage_services', 'manage_category', 'manage_staff', 'manage_admin', 'manage_inquiries', 'manage_customers', 'manage_expenses', 'customer_invoice', 'reports', 'settings', 'service_request_history', 'my_jobs', 'manage_inventory', 'point-of-sale', 'plan'];
  tempMenuList = [
    { name: 'Dashboard', link: 'home', class: 'active-list', icon: 'assets/icons/dashboard-icons/home.svg', child: [], id: 'dashboard' },

    { name: 'Planner', link: 'home/planner', icon: 'assets/icons/dashboard-icons/planner.svg', child: [], id: 'planner' },

    { name: 'Service Request', link: 'home/planner/create-service-request', icon: 'assets/icons/dashboard-icons/inquiries.svg', child: [], id: 'planner' },


    // {
    //   name: 'Planner',  icon: 'assets/icons/dashboard-icons/planner.svg', id: 'planner', dlink: 'home/planner', child: [
    //     { name: 'Create Service Request', link: 'home/planner/create-service-request' },
    //   ]
    // },


    { name: 'Manage Branches', link: 'home/profile/manage-branches', icon: 'assets/icons/dashboard-icons/manage-branch.svg', child: [], id: 'manage_branches' },

    {
      name: 'Manage Services', icon: 'assets/icons/dashboard-icons/services.svg', id: 'manage_services', dlink: 'home/manage-service', child: [
        { name: 'Create Service', link: 'home/manage-service/create-service' },
        { name: 'Services', link: 'home/manage-service/services-list' },
      ]
    },
    {
      name: 'Manage Categories', icon: 'assets/icons/dashboard-icons/dashboard.svg', id: 'manage_category', dlink: 'home/manage-category', child: [
        { name: 'Create Category', link: 'home/manage-category/create-category' },
        { name: 'Category List', link: 'home/manage-category' },
      ]
    },
    {
      name: 'Manage Staff', icon: 'assets/icons/dashboard-icons/staff.svg', id: 'manage_staff', dlink: 'home/staff', child: [
        { name: 'Create Staff', link: 'home/staff/create-staff' },
        { name: 'Staff List', link: 'home/staff/staff-list' },
      ]
    },
    {
      name: 'Manage Admins', icon: 'assets/icons/dashboard-icons/admin.svg', id: 'manage_admin', dlink: 'home/user', child: [
        { name: 'Create Admins', link: 'home/user/create-user' },
        { name: 'Admins List', link: 'home/user/user-list' },
      ]
    },
    {
      name: 'Manage Inquiries', icon: 'assets/icons/dashboard-icons/inquiries.svg', id: 'manage_inquiries', dlink: 'home/manage-inquiries', child: [
        { name: 'Inquiries', link: 'home/manage-inquiries/inquiries' },
      ]
    },

    { name: 'My Jobs', link: 'home/profile/my-jobs', icon: 'assets/icons/dashboard-icons/reports.svg', child: [], id: 'my_jobs' },

    {
      name: 'Manage Customers', icon: 'assets/icons/dashboard-icons/staff.svg', id: 'manage_customers', dlink: 'home/customers', child: [
        { name: 'Create Customer', link: 'home/customers/create-customer' },
        { name: 'Customer List', link: 'home/customers/customers-list' },
      ]
    },

    // {
    //   name: 'Manage Users', icon: 'assets/icons/dashboard-icons/admin.svg', id: 'manage_users',dlink: 'home/profile', child: [
    //     { name: 'Branch Managers', link: 'home/profile/branch-managers' },
    //     { name: 'Staff', link: 'home/profile/total-staff' },
    //   ]
    // },

    {
      name: 'Manage Expenses', icon: 'assets/icons/dashboard-icons/expenses.svg', id: 'manage_expenses', dlink: 'home/expenses', child: [
        { name: 'Expenses', link: 'home/expenses/expenses-list' },
        { name: 'Record Expenses', link: 'home/expenses/record-expenses' },
      ]
    },

    { name: 'Manage Inventory', link: 'manage-inventory', icon: 'assets/icons/dashboard-icons/inquiries.svg', child: [], id: 'manage-inventory' },
    { name: 'Point of Sale', link: 'point-of-sale', icon: 'assets/icons/dashboard-icons/inquiries.svg', child: [], id: 'point-of-sale' },

    { name: 'Customer Invoices', link: 'home/customerinvoice', icon: 'assets/icons/dashboard-icons/inquiries.svg', child: [], id: 'customer_invoice' },
    { name: 'Reports', link: 'sales-reports', icon: 'assets/icons/dashboard-icons/reports.svg', child: [], id: 'reports' },


    { name: 'Service Request History', link: 'home/service-request-history', icon: 'assets/icons/dashboard-icons/reports.svg', child: [], id: 'service_request_history' },

    { name: 'Reports', link: 'home/profile/reports', icon: 'assets/icons/dashboard-icons/inquiries.svg', child: [], id: 'report' },


    { name: 'Booking History', link: 'home/service-request-history', icon: 'assets/icons/dashboard-icons/planner.svg', child: [], id: 'service-request-history' },

    { name: 'Settings', link: 'home/profile/settings', icon: 'assets/icons/dashboard-icons/services.svg', child: [], id: 'settings' },
    // {
    //   name: 'Settings', icon: 'assets/icons/dashboard-icons/services.svg', id: 'settings', child: [
    //     { name: 'Week schedule', link: '' },
    //     { name: 'Holidays', link: '' },
    //   ]
    // },

    {
      name: 'Plan', icon: 'assets/icons/dashboard-icons/expenses.svg', id: 'plan', dlink: 'plan', child: [
        { name: 'Create Plan', link: 'home/plan/create-plan' },
        { name: 'Plan List', link: 'home/plan/plan-list' }
      ]
    },

  ]
  ngOnInit(): void {
    this.authService.onUserLogin.subscribe((data: any) => {
      // this.userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
      this.setMenus();
    });
    this.setMenus();

    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(
      (event: any) => {
        this.currentRoute = event.url;
      }
    );
  }

  logout = () => {
    this.authService.logout();
  }


  setMenus = () => {
    if (this.role === 'Branch Manager') {
      this.menus = ['profile', 'planner', 'manage_services', 'manage_category', 'manage_staff', 'manage_admin', 'manage_inquiries', 'manage_customers', 'manage_expenses', 'settings', 'service-request-history', 'plan'];
    } else if (this.role === 'Admin') {
      this.menus = ['planner', 'manage_inquiries', 'manage_customers',  'manage_expenses', 'settings',  'manage_staff'];
    } else if (this.role === 'SERVICE_PROVIDER') {
      this.menus = ['profile', 'manage_customers', 'manage_branches', 'settings'];
    } else if (this.role === 'Staff') {
      this.menus = ['profile', 'manage_customers', 'my_jobs'];
    }

    let rolesList = [];
    for (let i = 0; i < this.tempMenuList.length; i++) {
      if (this.menus.indexOf(this.tempMenuList[i].id) > -1) {
        rolesList.push(this.tempMenuList[i]);
      }
    }
    this.menuList = rolesList;
  }

}