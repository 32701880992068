
<!-- <div class="d-flex" id="wrapper">
    <app-side-menu></app-side-menu> -->
    <!-- Page content wrapper-->
    <!-- <div id="page-content-wrapper"> -->
        <!-- Top navigation-->
        <!-- <app-header></app-header> -->
        <!-- Breadcrumb-->
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <!-- Page content-->
        <!-- <div class="container-fluid"> -->
            <!-- <h1 class="mt-4">ScheduleItNow</h1>
            <p class="mt-5">naveen</p> -->
        <!-- </div>
    </div>
</div> -->

<div class="container-fluid">
    <div class="container">
        
        <div class="overviw-filter mt-5">
            <div class="of-inner">
                <p class="overview-title">Overview</p>
            </div>
            <div class="of-inner">
                <select class="form-select" aria-label="Default select example">
                    <option value="Today" selected>Today</option>
                    <option value="Yesterday">Yesterday</option>
                    <option value="This Week">This Week</option>
                    <option value="Last Week">Last Week</option>
                    <option value="This Month">This Month</option>
                    <option value="Last Month">Last Month</option>
                </select>
            </div>
        </div>

        <div class="db-charts-main-section">
            <div class="charts-inner-sec">
                <div class="chart-head-title">
                    <div class="c-h-inner">
                        <p class="chart-heading">Bookings</p>
                    </div>
                    <div class="c-h-inner content-right">
                        <p class="chart-heading">5,400</p>
                    </div>
                </div>
                <!-- Chart -->
                <div class="mt-4">
                    <canvas baseChart
                      [data]="barChartData"
                      [options]="barChartOptions"
                      [plugins]="barChartPlugins"
                      [type]="barChartType"
                      (chartHover)="chartHovered($event)"
                      (chartClick)="chartClicked($event)">
                    </canvas>
                </div>
                <!-- Chart -->
            </div>
            <div class="charts-inner-sec">
                <div class="chart-head-title">
                    <div class="c-h-inner">
                        <p class="chart-heading">Income</p>
                    </div>
                    <div class="c-h-inner content-right">
                        <p class="chart-heading">₹ 88,75,400</p>
                    </div>
                </div>
                <!-- Chart -->
                <div class="mt-4">
                    <canvas baseChart
                      [data]="ChartData"
                      [options]="barChartOptions"
                      [plugins]="barChartPlugins"
                      [type]="barChartType"
                      (chartHover)="chartHovered($event)"
                      (chartClick)="chartClicked($event)">
                    </canvas>
                </div>
                <!-- Chart -->
            </div>
        </div>


        <div class="overviw-filter mt-5 d-none">
            <div class="of-inner">
                <p class="overview-title">Active Bookings</p>
            </div>
            <div class="of-inner content-right">
                <a href="#">View All <img src="assets/icons/angle-right.svg" alt=""></a>
            </div>
        </div>

        <div class="active-bookings-wrapper d-none">
            <div class="booking-item-card">
                <!-- card header -->
                <div class="booking-head-title">
                    <div class="b-c-inner">
                        <p>Order #629854</p>
                    </div>
                    <div class="b-c-inner content-right">
                        <p class="b-date">23 June 2022 12:45 PM</p>
                    </div>
                </div>
                <!-- card header -->
                <!-- card body -->
                <div class="booking-card-body mt-3">
                    <div class="c-b-inner">
                        <img src="assets/images/hair-cut.jpg" alt="">
                    </div>
                    <div class="c-b-inner">
                        <p class="b-title">Hair Cut</p>
                        <p class="s-qty">Qty : 02</p>
                        <p class="s-price">Booking Total : ₹ 5,524</p>
                    </div>
                    <div class="c-b-inner content-right">
                        <p class="p-mode">COD</p>
                    </div>
                </div>
                <!-- card body -->
            </div>
            <div class="booking-item-card">
                <!-- card header -->
                <div class="booking-head-title">
                    <div class="b-c-inner">
                        <p>Order #629854 <span class="n-service">NEW</span></p>
                    </div>
                    <div class="b-c-inner content-right">
                        <p class="b-date">23 June 2022 12:45 PM</p>
                    </div>
                </div>
                <!-- card header -->
                <!-- card body -->
                <div class="booking-card-body mt-3">
                    <div class="c-b-inner">
                        <img src="assets/images/threading&Waxing.jpg" alt="">
                    </div>
                    <div class="c-b-inner">
                        <p class="b-title">Waxing and threading</p>
                        <p class="s-qty">Qty : 02</p>
                        <p class="s-price">Booking Total : ₹ 5,524</p>
                    </div>
                    <div class="c-b-inner content-right">
                        <p class="p-mode">COD</p>
                    </div>
                </div>
                <!-- card body -->
            </div>
            <div class="booking-item-card">
                <!-- card header -->
                <div class="booking-head-title">
                    <div class="b-c-inner">
                        <p>Order #629854</p>
                    </div>
                    <div class="b-c-inner content-right">
                        <p class="b-date">23 June 2022 12:45 PM</p>
                    </div>
                </div>
                <!-- card header -->
                <!-- card body -->
                <div class="booking-card-body mt-3">
                    <div class="c-b-inner">
                        <img src="assets/images/hair-cut.jpg" alt="">
                    </div>
                    <div class="c-b-inner">
                        <p class="b-title">Hair Cut</p>
                        <p class="s-qty">Qty : 02</p>
                        <p class="s-price">Booking Total : ₹ 5,524</p>
                    </div>
                    <div class="c-b-inner content-right">
                        <p class="p-mode d-none">COD</p>
                        <p class="p-mode-cash">CASH</p>
                    </div>
                </div>
                <!-- card body -->
            </div>
        </div>


    </div>
    <!-- <router-outlet></router-outlet> -->
</div>
