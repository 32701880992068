<div class="thanks-page-container-data">
    <div class="container">
        <div class="thanks-message-info">
            <div class="img-block">
                <img src="assets/icons/success-gif.gif">
            </div>
            <div class="content-block">
              <ng-container >
                <div class="title">Booking Confirmed</div>
                <div class="title">Booking ID {{orderId}}</div>
                <div class="info-text">Service requested created Successfully </div>
                <div class="back-link" (click)="goToPlannerPage()">{{'Go to planner'}}</div>
              </ng-container>
              
            </div>
        </div>
    </div>
</div>