import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataService } from '../services/data.service';
import { StorageService } from '../services/storage.service';
import { AuthInterceptor } from '../interceptor/auth.interceptor';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { QuillModule } from 'ngx-quill';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../services/data-formatter.service';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule, Dialog } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { LoaderInterceptor } from '../interceptor/loader.interceptor';
import {PaginatorModule} from 'primeng/paginator';
import { LoaderComponent } from '../loader/loader.component';
import {CarouselModule} from 'primeng/carousel';






@NgModule({
  declarations: [LoaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    TabsModule.forRoot(),
    TableModule,
    TimepickerModule,
    NgxDropzoneModule,
    QuillModule,
    NgbModule,
    AccordionModule,
    CalendarModule,
    DialogModule,
    InputSwitchModule,
    PaginatorModule,
    CarouselModule
  ],
  exports: [
    CalendarModule,
    DialogModule,
    InputSwitchModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    TabsModule,
    TableModule,
    TimepickerModule,
    NgxDropzoneModule,
    QuillModule,
    NgbModule,
    AccordionModule,
    CalendarModule,
    DialogModule,
    PaginatorModule,
    LoaderComponent,
    CarouselModule
  ], 
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    DataService,
    StorageService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
  ]
})
export class SharedModule { }
