import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userDetails: any;
  showProfile = false;
  msgs1: any;
  // colorl:any;




  constructor(public authService: AuthService, public storageService: StorageService, public dataService: DataService, public router: Router,) { }


  ngOnInit(): void {

    this.msgs1 = [

      { severity: 'info', summary: 'Under Review', detail: '<br/>jjfkjs' },

      { severity: 'error', summary: 'Rejected!', detail: '' }
    ];



    this.authService.onUserLogin.subscribe((data: any) => {
      this.userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
      this.showHideProfile();
    });
    this.userDetails = JSON.parse(<string>this.storageService.getItem("user_info"));
    this.showHideProfile();
  }

  showHideProfile = () => {
    const role = this.userDetails?.role
    this.showProfile = false;
    if (role === 'SERVICE_PROVIDER') {
      this.showProfile = true;
    }
  }

  logout = () => {
    this.authService.logout();
  }

  gotoChangePasswordPage() {
    // Route with Reload
    this.router.navigate(['/change-password'])
      .then(() => {
        window.location.reload();
      });
  }

}
