<div class="container">
    <section class="drop-dwn">
        <h6 class="heading01">FINANCIAL YEAR</h6>
    <div class="down-ld">
        <div>
            <form id="form1">
                <select class="select-form">
                 <option value='2021'>2022</option>
                  <option value='2020'>2021</option>
                </select>
          </form></div>
    <div>
   
    <img src="assets/icons/download.svg" alt="" class="mt-3"></div>
    <div class="down-id-form  mt-3">Dropdown</div>

</div>
</section>
<div class="db-charts-main-section">
    <div class="charts-inner-sec">
        <div class="chart-head-title">
            <div class="c-h-inner">
                <p class="mt-2">Revenue & Expenses</p>
            </div>
            <div class="c-h-inner content-right">
                <p class="mt-2">10000</p>
            </div>
        </div>
        <!-- Chart -->
        <div class="mt-4">
            <canvas baseChart
              [data]="barChartData"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [type]="barChartType"
              (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)">
            </canvas>
        </div>
        <!-- Chart -->
    </div>
    <div class="charts-inner-sec">
        <div class="chart-head-title">
            <div class="c-h-inner">
                <p class="mt-2">Refund</p>
            </div>
            <div class="c-h-inner content-right">
                <p class="mt-2">₹75,400</p>
            </div>
        </div>
        <!-- Chart -->
        <div class="mt-4">
            <canvas baseChart
              [data]="ChartData"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [type]="barChartType"
              (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)">
            </canvas>
        </div>
        <!-- Chart -->
    </div>

    <div class="charts-inner-sec">
        <div class="chart-head-title">
            <div class="c-h-inner">
                <p class="mt-2">Financial</p>
            </div>
            <div class="c-h-inner content-right">
                <p class="mt-2">₹10,000</p>
            </div>
        </div>
        <!-- Chart -->
      <div class="canvas-wraper">
        <canvas  baseChart 
            [data]="pieChartData"
            [type]="pieChartType"
            [options]="pieChartOptions"
            [plugins]="pieChartPlugins">
        </canvas>
        </div> 
        <!-- Chart -->
    </div>
</div>

    <section class="subscriptions">
        <h6>NET SALES</h6>
        <div class="tbl-hld">
            <table id="tblNetSales">
                <thead>
                    <tr class="tthead">
                        <td>TAXES</td>
                        <td>TOTAL</td>
                        <td>DAY</td>
                        <td>1</td>
                        <td>(2)</td>
                        <td>(3)</td>
                        <td>(4)</td>
                        <td>(5)</td>
                        <td>(6)</td>
                        <td>(7)</td>
                        <td>(8)</td>
                        <td>(9</td>
                        <td>(10)</td>
                        <td>(11)</td>
                        <td>(12)</td>
                        <!-- <td>{{numberToDay(13)}}</td>
                        <td>{{numberToDay(14)}}</td>
                        <td>{{numberToDay(15)}}</td>
                        <td>{{numberToDay(16)}}</td>
                        <td>{{numberToDay(17)}}</td>
                        <td>{{numberToDay(18)}}</td>
                        <td>{{numberToDay(19)}}</td>
                        <td>{{numberToDay(20)}}</td>
                        <td>{{numberToDay(21)}}</td>
                        <td>{{numberToDay(22)}}</td>
                        <td>{{numberToDay(23)}}</td>
                        <td>{{numberToDay(24)}}</td>
                        <td>{{numberToDay(25)}}</td>
                        <td>{{numberToDay(26)}}</td>
                        <td>{{numberToDay(27)}}</td>
                        <td>{{numberToDay(28)}}</td>
                        <td>{{numberToDay(29)}}</td>
                        <td>{{numberToDay(30)}}</td>
                        <td>{{numberToDay(31)}}</td> -->
                    </tr>
                    <tbody>
                        <tr class="ttbody">
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                        </tr>
                    </tbody>
                </table>
            </div>
    
    <h6 class="mt-5">EXPENSES</h6>
    <div class="tbl-hld">
        <table id="tblExpenses">
            <thead>
                <tr class="tthead2">
                    <td>TYPE</td>
                    <td>JAN</td>
                    <td>FEB</td>
                    <td>MAR</td>
                    <td>APR</td>
                    <td>MAY</td>
                    <td>JUN</td>
                    <td>JUL</td>
                    <td>AUG</td>
                    <td>SEP</td>
                    <td>OCT</td>
                    <td>NOV</td>
                    <td>DEC</td>
                </tr>
            </thead>
            <tbody>
                <tr class="ttbody2">
                    <td>Fuel</td>
                    <td>1</td>
                    <td>3</td>
                    <td>4</td>
                    <td>1</td>
                    <td>2</td>
                    <td>3</td>
                    <td>4</td>
                </tr>
               <tr class="tttotal2">
                    <td>Total</td>
                    <td>jan</td>
                    <td>jan</td>
                    <td>jan</td>
                    <td>jan</td>
                    <td>jan</td>
                    <td>jan</td>
                    <td>jan</td>
               
                </tr>
            </tbody>
        </table>
    </div>
    
    </section>
</div>
