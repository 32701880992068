<div class="login-container-page row" *ngIf="!showSuccessPage">
  <div class="login-form-section col-lg-6 col-md-6 col-sm-6 col-12">
    <!-- Reset Pin -->

    <ng-container>
      <div class="heading-text">Forgot password</div>
      <!-- <div class="desc-text">We have sent generated a random PIN which will be found Under inbox section of your email account.</div> -->
      <form [formGroup]="frgtPwdForm" (ngSubmit)="onSubmit($event)">
        <div class="input-block">
          <div class="label-text">EMAIL<sup>*</sup></div>
          <input type="text" placeholder="Ex:abdul@gmail.com" class="form-control forgot-input" formControlName="email">
          <div class="valid-check">
            <i class="bi bi-check-circle-fill success" *ngIf="emailControls.valid  && formSubmited"></i>
            <i class="bi bi-exclamation-circle-fill error" *ngIf="emailControls.invalid  && formSubmited"></i>
          </div>
          <span class="error-text" *ngIf="emailControls.invalid  && formSubmited">Email Required</span>
        </div>
        <span *ngIf="errMsg">{{errMsg}}</span>
        <button class="sb-primary-btn w-100" type="submit">Submit</button>
        <div class="dont-have-acc text-center">
          <span class="normal-text">Already have an account?</span>
          <span class="link-text" [routerLink]="['../sign-in']">Sign In</span>
        </div>
      </form>
    </ng-container>

  </div>
  <div class="info-block-with-img col-lg-6 col-md-6 col-sm-6 col-6">
    <div class="img-block-wrapper">
      <!-- <img src="assets/icons/login-signup/3275433 1.svg"> -->
      <img src="assets/icons/login-signup/Frame 39591 (1).png" class="forgot-img">
    </div>
    <!-- <div class="info-layer">
      <div class="primary-text">“We've been using ScheduleItNow  to kickstart every new job and cannot imagine working without
        it.”</div>
      <div class="name-u">Adam bayer</div>
      <div class="designation">Senior Technician</div>
      <div class="service-name">Adam's Repair Services</div>
      <div class="next-btn">
        Next <i class="bi bi-arrow-right"></i>
      </div>
    </div> -->
  </div>
</div>

<div *ngIf="showSuccessPage">
  <div class="thanks-page-container-data">
    <!-- <app-breadcrumb></app-breadcrumb> -->
    <div class="container">
      <div class="thanks-message-info">
        <div class="img-block">
          <img src="assets/icons/success-gif.gif">
        </div>
        <div class="content-block">
          <div>
            <div class="title">New Password has been sent to your email.</div>
            <div class="back-link" [routerLink]="'/sign-in'">Back to Login</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>