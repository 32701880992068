<div class="container" id="main-page-wrapper">
    <div class="top_content_wrapper">
        <h2 class="top-con">Simple Transparent Pricing</h2>
        <h3>No Contracts, No Surprise Fees</h3>
    </div>
    <div class="pricing_tabs">
        <p-tabView>
            <p-tabPanel header="Monthly">

                <div class="content-wrapper">
                    <div class="column-wrapper-one table-column-wrapper">
                        <img src="assets/images/Mask Group 6.png" class="img-wrapper" alt="">
                        <!-- <p class="odd_line">Included bookings </p>
                <p class="even_line"></p> -->
                        <table class="table table-striped mt-5">
                            <tbody>
                                <tr>
                                    <td>Included bookings </td>
                                </tr>
                                <tr>
                                    <td>Custom Features</td>
                                </tr>
                                <tr>
                                    <td>Users/Providers*</td>
                                </tr>
                                <tr>
                                    <td>Admin App </td>
                                </tr>
                                <tr>
                                    <td>Client App </td>
                                </tr>
                                <tr>
                                    <td>Booking Websites</td>
                                </tr>
                                <tr>
                                    <td>Booking Widgets </td>
                                </tr>
                                <tr>
                                    <td>Directory Listing </td>
                                </tr>
                                <tr>
                                    <td>Coupons & Gift Cards</td>
                                </tr>
                                <tr>
                                    <td>Sales (POS)</td>
                                </tr>
                                <tr>
                                    <td> Link Removal</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>



                    <!--------------------------------------------------------------->

                    <!----------------trail-->
                    <div class="column-wrapper">
                        <div class="row  table-column-wrapper mt-2">
                            <div class="col-md-4 pr-5">
                                <img src="assets/icons/free trial.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Free trail</p>
                                <p> <b class="price-wrap">₹ 0</b><span class="month-wrapper">/Month</span></p>
                            </div>

                        </div>

                        <button type="button" class="trial-btn"> Star Free Trial</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>50 </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-------------------Basic card-->
                    <div class="column-wrapper">


                        <div class="row  table-column-wrapper mt-2">
                            <div class="col-md-4 pr-5">
                                <img src="assets/icons/Basic icon.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Basic</p>
                                <p><b class="price-wrap">₹ 700</b><span class="month-wrapper">/Month</span></p>
                            </div>

                        </div>

                        <button type="button" class="trial-btn">Purchase</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>100 </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!---------Standard------------->
                    <div class="column-wrapper">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="bestSellerRgt">Popular</div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-4">
                                <img src="assets/icons/standard-icon.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Standard</p>
                                <p> <b class="price-wrap">₹ 1499</b><span class="month_wrapper">/Month</span></p>
                            </div>

                        </div>


                        <button type="button" class="trial-btn">Purchase</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>500 </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!----------------premium-->
                    <div class="column-wrapper">
                        <div class="row  table-column-wrapper mt-2">
                            <div class="col-md-4 pr-5">
                                <img src="assets/icons/premium-icon.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Premium</p>
                                <p> <b class="price-wrap">₹2,499</b><span class="month-wrapper">/Month</span></p>
                            </div>

                        </div>
                        <button type="button" class="trial-btn">Purchase</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>2000 </td>
                                </tr>
                                <tr>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>50</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!----------------------code for mobile view-->
                <!--------------------free-trail---------->
                <div class="row  mobile-top-wrapper mt-5">
                    <div class="col-md-12">
                        <p class="mobile-free-trail-wrapper">Free trail</p>
                        <p class="plan__header-price-value">₹ &nbsp;0
                            <span class="plan-header-month">/Month</span>

                        </p>
                    </div>
                </div>
                <div class="mobile-content-wrapper">
                    <div class="mobile-view-content-wrapper">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>Included bookings </td>
                                </tr>
                                <tr>
                                    <td>Custom Features</td>
                                </tr>
                                <tr>
                                    <td>Users/Providers*</td>
                                </tr>
                                <tr>
                                    <td>Admin App </td>
                                </tr>
                                <tr>
                                    <td>Client App </td>
                                </tr>
                                <tr>
                                    <td>Booking Websites</td>
                                </tr>
                                <tr>
                                    <td>Booking Widgets </td>
                                </tr>
                                <tr>
                                    <td>Directory Listing </td>
                                </tr>
                                <tr>
                                    <td>Coupons & Gift Cards</td>
                                </tr>
                                <tr>
                                    <td>Sales (POS)</td>
                                </tr>
                                <tr>
                                    <td> Link Removal</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="mobile-view-mobile">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>50/month</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
                <!-------------------------Mobileview------------->
                <!--------------------basic-trail--------->
                <div class="row  mobile-top-wrapper">
                    <div class="col-md-12">
                        <p class="mobile-free-trail-wrapper">Basic</p>
                        <p class="plan__header-price-value">₹ &nbsp;700
                            <span class="plan-header-month">/Month</span>

                        </p>
                    </div>
                </div>
                <div class="mobile-content-wrapper">
                    <div class="mobile-view-content-wrapper">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>Included bookings </td>
                                </tr>
                                <tr>
                                    <td>Custom Features</td>
                                </tr>
                                <tr>
                                    <td>Users/Providers*</td>
                                </tr>
                                <tr>
                                    <td>Admin App </td>
                                </tr>
                                <tr>
                                    <td>Client App </td>
                                </tr>
                                <tr>
                                    <td>Booking Websites</td>
                                </tr>
                                <tr>
                                    <td>Booking Widgets </td>
                                </tr>
                                <tr>
                                    <td>Directory Listing </td>
                                </tr>
                                <tr>
                                    <td>Coupons & Gift Cards</td>
                                </tr>
                                <tr>
                                    <td>Sales (POS)</td>
                                </tr>
                                <tr>
                                    <td> Link Removal</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="mobile-view-mobile">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>100/month</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
                <!--------Standard mobile-wrapper-------->
                <div class="row  mobile-top-wrapper">
                    <div class="col-md-12">
                        <p class="mobile-free-trail-wrapper">Standard</p>
                        <p class="plan__header-price-value">₹ &nbsp;1499
                            <span class="plan-header-month">/Month</span>

                        </p>
                    </div>
                </div>
                <div class="mobile-content-wrapper">
                    <div class="mobile-view-content-wrapper">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>Included bookings </td>
                                </tr>
                                <tr>
                                    <td>Custom Features</td>
                                </tr>
                                <tr>
                                    <td>Users/Providers*</td>
                                </tr>
                                <tr>
                                    <td>Admin App </td>
                                </tr>
                                <tr>
                                    <td>Client App </td>
                                </tr>
                                <tr>
                                    <td>Booking Websites</td>
                                </tr>
                                <tr>
                                    <td>Booking Widgets </td>
                                </tr>
                                <tr>
                                    <td>Directory Listing </td>
                                </tr>
                                <tr>
                                    <td>Coupons & Gift Cards</td>
                                </tr>
                                <tr>
                                    <td>Sales (POS)</td>
                                </tr>
                                <tr>
                                    <td> Link Removal</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="mobile-view-mobile">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>500/month</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
                <!------------------mobile-view-premium-->
                <div class="row  mobile-top-wrapper">
                    <div class="col-md-12">
                        <p class="mobile-free-trail-wrapper">Premium</p>
                        <p class="plan__header-price-value">₹ &nbsp;2499
                            <span class="plan-header-month">/Month</span>

                        </p>
                    </div>
                </div>
                <div class="mobile-content-wrapper">
                    <div class="mobile-view-content-wrapper">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>Included bookings </td>
                                </tr>
                                <tr>
                                    <td>Custom Features</td>
                                </tr>
                                <tr>
                                    <td>Users/Providers*</td>
                                </tr>
                                <tr>
                                    <td>Admin App </td>
                                </tr>
                                <tr>
                                    <td>Client App </td>
                                </tr>
                                <tr>
                                    <td>Booking Websites</td>
                                </tr>
                                <tr>
                                    <td>Booking Widgets </td>
                                </tr>
                                <tr>
                                    <td>Directory Listing </td>
                                </tr>
                                <tr>
                                    <td>Coupons & Gift Cards</td>
                                </tr>
                                <tr>
                                    <td>Sales (POS)</td>
                                </tr>
                                <tr>
                                    <td> Link Removal</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="mobile-view-mobile">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>2000/month</td>
                                </tr>
                                <tr>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>5o</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/mobile-Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
                <!------------------------------------>
            </p-tabPanel>
            <!--------------------------------------->
            <p-tabPanel header="Annual">
                <div class="content-wrapper">
                    <div class="column-wrapper-one table-column-wrapper">
                        <img src="assets/images/Mask Group 6.png" class="img-wrapper" alt="">
                        <!-- <p class="odd_line">Included bookings </p>
                <p class="even_line"></p> -->
                        <table class="table table-striped mt-5">
                            <tbody>
                                <tr>
                                    <td>Included bookings </td>
                                </tr>
                                <tr>
                                    <td>Custom Features</td>
                                </tr>
                                <tr>
                                    <td>Users/Providers*</td>
                                </tr>
                                <tr>
                                    <td>Admin App </td>
                                </tr>
                                <tr>
                                    <td>Client App </td>
                                </tr>
                                <tr>
                                    <td>Booking Websites</td>
                                </tr>
                                <tr>
                                    <td>Booking Widgets </td>
                                </tr>
                                <tr>
                                    <td>Directory Listing </td>
                                </tr>
                                <tr>
                                    <td>Coupons & Gift Cards</td>
                                </tr>
                                <tr>
                                    <td>Sales (POS)</td>
                                </tr>
                                <tr>
                                    <td> Link Removal</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!----------------trail-->
                    <div class="column-wrapper">
                        <div class="row  table-column-wrapper mt-2">
                            <div class="col-md-4 pr-5">
                                <img src="assets/icons/free trial.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Free trail</p>
                                <p> <b class="price-wrap">₹ 0</b><span class="month-wrapper">/Month</span></p>
                            </div>

                        </div>

                        <button type="button" class="trial-btn"> Star Free Trial</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>50 </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-------------------Basic card-->
                    <div class="column-wrapper">


                        <div class="row  table-column-wrapper mt-2">
                            <div class="col-md-4 pr-5">
                                <img src="assets/icons/Basic icon.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Basic</p>
                                <p><b class="price-wrap">₹ 700</b><span class="month-wrapper">/Month</span></p>
                            </div>

                        </div>

                        <button type="button" class="trial-btn">Purchase</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>100 </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!---------Standard------------->
                    <div class="column-wrapper">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="bestSellerRgt">popular</div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-4">
                                <img src="assets/icons/standard-icon.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Standard</p>
                                <p> <b class="price-wrap">₹ 1499</b><span class="month_wrapper">/Month</span></p>
                            </div>

                        </div>


                        <button type="button" class="trial-btn">Purchase</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>500 </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-close-circle-outline.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!----------------premium-->
                    <div class="column-wrapper">
                        <div class="row  table-column-wrapper mt-2">
                            <div class="col-md-4 pr-5">
                                <img src="assets/icons/premium-icon.svg" class="icon_free">
                            </div>
                            <div class="col-md-8">
                                <p class="top-heading">Premium</p>
                                <p> <b class="price-wrap">₹2,499</b><span class="month-wrapper">/Month</span></p>
                            </div>

                        </div>
                        <button type="button" class="trial-btn">Purchase</button>
                        <table class="table table-striped trial-wrapper mt-5">
                            <tbody>
                                <tr>
                                    <td>2000 </td>
                                </tr>
                                <tr>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>50</td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                                <tr>
                                    <td> <img src="assets/icons/Icon ionic-ios-checkmark-circle.svg"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!---------Annual -------------------------->
            </p-tabPanel>

        </p-tabView>
    </div>


    <!-------------------->
    <div class="user_wrapper">
        <p><b>* User</b> is an employee with access to the admin side,<br>
            <b>Provider</b> is an employee that provides the service.<br>
            <b>Employee </b>can be both user and provider at the same time.
    </div>
    <!-------------------->
    <div class="free-trail-wrapper">
        <button type="submit" class="trail-btn"><img src="assets/icons/image 17.svg"> Start a free trail now
            !</button><br>
        <p class="trail-content">Get 30 days for free trail</p>
        <p class="trail-cont">includes most features and 50 bookings</p>


        <h1 class="mt-5">Our plans include</h1>

    </div>
    <!------------Plans include------->
    <div class="plans_include_wrapper">
        <div class="shedule-plan">
            <div class="icon-center_Wrapper">
                <img src="assets/icons/calendar_planning.svg">
                <h1 class="sche_f">Scheduling</h1>
            </div>
            <p>
                Rich custom wording<br>

                Advanced employee and resource schedule management<br>

                Associate particular services to particular service providers<br>

                Set different service duration and buffer times<br>

                Set prices for different services<br>

                Define the number of clients each service provider can accept at once<br>

                Recurring appointments<br>

                Event bookings</p>


        </div>
        <div class="sheduleit-plan">
            <div class="icon-center_Wrapper">
                <img src="assets/icons/web_planner.svg">
                <h1 class="sche_f">Booking Website</h1>
            </div>
            <p>
                Online bookings for your clients

                Manual bookings for you

                Mobile responsive booking website

                Rich design options for booking website

                Booking website in clients time zone

                Website intergration using iframe

                Feature client reviews on your booking website

                Directory listing in Booking.page

                Bookings via Facebook Page

                Booking website can be disabled
            </p>


        </div>
        <div class="shedule-plan">
            <div class="icon-center_Wrapper">
                <img src="assets/icons/admistration_planning.svg">
                <h1 class="sche_f">Administration</h1>
            </div>
            <p>
                Admin App (iOS & Android)

                Easy and accessible online booking calendar

                Filtered view of booking calendar

                Easy cancelling & rescheduling

                Multiple system users

                Assign different user roles

                View client history

                Make notes on bookings


            </p>


        </div>
        <div class="sheduleit-plan">
            <div class="icon-center_Wrapper">
                <img src="assets/icons/bell_planning.svg">
                <h1 class="sche_f">Email and browser notifications</h1>
            </div>
            <p>
                Automatic confirmation emails

                Automatic reminder emails

                Automatic cancellation emails

                Automatic notification of rescheduling by admin

                Automatic feedback request after the service (optional)

                Customize your notifications with your own words

                Clients can add appointments to their calendars with one click

                Notifications in client's time zone

                Browser notifications for admin


            </p>


        </div>
    </div>
    <!------------------->
    <div class="multi_wrapper mt-5">

        <div class="multiple_lang pt-3">
            <div class="row">
                <div class="col-md-3 col-6">
                    <img src="assets/icons/language.svg">
                    <h3> Multiple Languages</h3>
                </div>
                <div class="col-md-3 col-6">
                    <p>English</p>
                    <p>Deutsch</p>
                    <p>中文</p>
                </div>
                <div class="col-md-3 col-6">
                    <p>Français</p>
                    <p>Русский</p>
                    <p>日本語</p>
                </div>
                <div class="col-md-3 col-6">
                    <p>Español.</p>
                    <p>Português</p>
                    <p>日本語</p>
                </div>
            </div>
        </div>
        <div class="multiple_lang pt-3">
            <div class="row">
                <div class="col-md-6">
                    <img src="assets/icons/comments.svg " class="img-aline">
                    <h3 class="comment_line">Support</h3>
                </div>
                <div class="col-md-6 support_line">
                    <p>Help Center</p>
                    <p>Email</p>
                    <p>Live chat</p>
                    <p>Vedio tutorials</p>

                </div>
            </div>
        </div>
    </div>
    <!------------for mobile view Our plan Include------------------->

    <div class="mobile-plan-include">
        <div class="row">
            <div class="col-md-12">
                <p-accordion>
                    <p-accordionTab header="Scheduling">
                        <p> Rich custom wording</p>
                        <p>Advanced employee and resource schedule management</p>
                        <p>Associate particular services to particular service providers</p>
                        <p>Set different service duration and buffer times</p>
                        <p>Set prices for different services</p>
                        <p>Define the number of clients each service provider can accept at once</p>
                        <p>Recurring appointments</p>
                        <p>Event bookings</p>
                    </p-accordionTab>
                    <p-accordionTab header="Booking Website">
                    
                        <p>Automatic confirmation emails</p>
            
                        <p> Automatic reminder emails</p>
            
                        <p>Automatic cancellation emails</p>
            
                        <p>Automatic notification of rescheduling by admin</p>
            
                        <p> Automatic feedback request after the service (optional)</p>
            
                        <p>Customize your notifications with your own words</p>
            
                        <p>  Clients can add appointments to their calendars with one click</p>
            
                            <p> Notifications in client's time zone</p>
            
                                <p> Browser notifications for admin
                                </p>
            
            
                    </p-accordionTab>
                    <p-accordionTab header="Administration">
                        <p> Admin App (iOS & Android)</p>
            
                        <p> Easy and accessible online booking calendar</p>
             
                        <p> Filtered view of booking calendar</p>
            
                        <p> Easy cancelling & rescheduling</p>
            
                        <p>Multiple system users</p>
            
                        <p> Assign different user roles</p>
            
                        <p>View client history</p>
            
                        <p> Make notes on bookings</p>
            
            
                        
            
                    </p-accordionTab>
                    <p-accordionTab header="Email and Browser notifications">
                        <p>
                            Automatic confirmation emails</p>
            
                            <p> Automatic reminder emails</p>
            
                            <p>Automatic cancellation emails</p>
            
                            <p> Automatic notification of rescheduling by admin</p>
            
                            <p> Automatic feedback request after the service (optional)</p>
            
                            <p>Customize your notifications with your own words</p>
            
                            <p> Clients can add appointments to their calendars with one click</p>
            
                            <p>Notifications in client's time zone</p>
            
                            <p>Browser notifications for admin</p>
            
            
                    
            
                    </p-accordionTab>
                    <p-accordionTab header="Multiple Languages">
                        <p>English</p>
                        <p>Deutsch</p>
                        <p>中文 </p>
                        <p>Français</p>
                        <p>Русский</p>
                        <p>日本語</p>
                        <p>Español</p>
                        <p>Português</p>
                        <p>日本語</p>



                    </p-accordionTab>
                    <p-accordionTab header="Support">
                        <p>Help Center</p>
                        <p>Email</p>
                        <p>Live chat</p>
                        <p>Vedio tutorials</p>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </div>

        <!------------------------->
        <div class="pay-now mt-3">

            <p class="pay-new"><span class="pay-wrapper">Don't</span> need to pay now!</p>
            <p class="free-wrap"> Try most paid features for free</p>
            <button type="submit" class="trail-btn"><img src="assets/icons/image 17.svg"> Start a free trail now
                !</button>

        </div>
        <!--------------quick Implementation-->

        <div class="quick-wrapper mt-5">
            <div class="left-content-wrapper">
                <h3>Are you looking for</h3>
                <h2>quick implementation?</h2>
                <p class="agent-wrapper">Let our Live Chat agents set up the system for you!</p>
                <p class="agent-wrapper-one">Don't have the time to do it yourself?<br>
                    For as little as $80, you get the system set up for you, including a<br> full month of premium
                    subscription, worth $60.</p>
            </div>
            <div class="right-content-wrapper">
                <img src="assets/icons/implementation illu.svg" class="img-quicker">
            </div>
        </div>
<!-------------------for mobil-view-->
        <div class="mobile-quick-implementation">
        <p class="mobile-agent-wrapper">Let our Live Chat agents set up the system for you!</p>
        <p class="mobile-agent-wrapper-one">Don't have the time to do it yourself?<br>
            For as little as $80, you get the system set up for you, including a<br> full month of premium
            subscription, worth $60.</p>
        </div>
        <!-----------Business section-------->

        <!---------------->
        <!----------------->
        <div class="quick-check-wrapper mt-5">
            <div class="right-content-wrapper pt-4">
                <img src="assets/icons/check illu.svg">
            </div>
            <div class="left-content-wrapper mt-5">
                <h3>Check out what we can do for you!</h3>
                <p>Explore the range of features that make servu.com scheduling software an excellent match for you.
                    Servu.com offers over 60 custom features to reflect your needs and style.</p>
                <div>
                    <button type="submit" class="view-btn"> View full features list &nbsp;&nbsp; <img
                            src="assets/icons/arrow-right-P.svg"></button>
                </div>
                <div class="mt-3">
                    <button type="submit" class="features-btn">Features per industry</button>
                </div>
            </div>
        </div>
        <!--------------------------------mobile-quick-check -wrapper-->
       <div>
                <h3 class="mobile-loking">Check out what we can do for you!</h3>
                <img src="assets/icons/check illu.svg" class="mobile-looking-img">
                <p class="quick-para-wrapper">Explore the range of features that make servu.com scheduling software an excellent match for you.
                    Servu.com offers over 60 custom features to reflect your needs and style.</p>
                  
                  
                    <button type="submit" class="mobile-features-btn"> View full features list &nbsp;&nbsp; <img
                        src="assets/icons/arrow-right-P.svg"></button><br><br>
                      <button type="submit" class="mobile-view-btn">Features per industry</button>
                   
       
            </div>
            
        <!------------------------------------------------------------->
        <!-------------Cofused section------>
        <div class="focus-wrapper mt-5">
            <h1 class="s-wrap">Still Confused?</h1>
            <p class="help-wrap">Let us help you</p>
            <div class="still-focus-wrapper">
                <div class="frst-focus-wrapper">
                    <div> <img src="assets/icons/live chat.svg"></div>
                    <p class="mt-3">Let our Live Chat Support help you with any questions you have.</p>
                </div>

                <div class="frst-focus-wrapper">
                    <div> <img src="assets/icons/help center.svg"></div>
                    <p class="mt-3">Find detailed instructions and explanation for everything in the system.</p>
                </div>

                <div class="third-focus-wrapper">

                    <div> <img src="assets/icons/vedio tutorials.svg"></div>
                    <p class="mt-3">Check out our
                        YouTube channel to search our vedio tutorials</p>
                </div>
            </div>

        </div>
    </div>