import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  frgtPwdForm: any;
  formSubmited = false;
  showSuccessPage = false;
  errMsg = '';






  constructor( public authService: AuthService) { 
  
  }

  ngOnInit(): void {
    this.frgtPwdForm = new FormGroup({
      email: new FormControl('',[Validators.required, Validators.email])
    });
  }

  get emailControls() {return this.frgtPwdForm.get('email')};

  onSubmit = (event: any) => {
    event.preventDefault();
    this.formSubmited = true;
    this.errMsg ='';  
    if (this.frgtPwdForm.valid) {
      this.authService.forgotPwd(this.frgtPwdForm.value).subscribe((res: any) => {
        if (res && !res.errorCode) {
          this.showSuccessPage = true;
        } else {
          this.errMsg = res.message;
        }
      });
    }
  }

}
