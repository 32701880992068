import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  signInForm: any;
  formSubmited = false;
  errMsg: any;
  showPwdLabel = "password";
  constructor(
    public router: Router,
    public authService: AuthService, public storageService: StorageService) {
  }

  selectbranch = [
    { id: 1, icon: 'assets/icons/chevron-down.svg', name: 'Panjagutta Branch' }
  ];


  ngOnInit(): void {
    this.signInForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      pwd: new FormControl('', [Validators.required])
    });
  }

  get emailControls() { return this.signInForm.get('email'); }
  get pwdControls() { return this.signInForm.get('pwd'); }
  get agreeControls() { return this.signInForm.get('agree'); }

  // onSubmit = (event: any) => {
  //   event.preventDefault();
  //   this.formSubmited = true;
  //   if (this.signInForm.valid) {
  //     this.authService.signIn(this.signInForm.value).subscribe((res: any) => {
  //       if (res && !res.errorCode) {
  //         console.log('success', res);
  //         if (res.branches?.length > 1 && res.role === 'Branch Manager') {
  //           this.router.navigate(['/branch-selection']);
  //         } else if(res.branches?.length  === 0  && res.role === 'Branch Manager'){
  //           Swal.fire({
  //             text: 'No branches found for this branch Manager',
  //             icon: 'error',
  //             confirmButtonText: 'Ok',
  //             allowOutsideClick: false,
  //             allowEscapeKey: false
  //           })
  //         }else{
  //           // this.router.navigate(['/home/planner']);
  //           this.router.navigate(['/home/planner']).then(() => {
  //             window.location.reload();
  //           });
            
  //         }
          
  //       } else {
  //         console.log('fail');
  //         this.errMsg = res.message;
  //       }
  //     });
  //   }
  // }

  onSubmit = (event: any) => {
    event.preventDefault();
    this.formSubmited = true;
    
    if (this.signInForm.valid) {
      this.authService.signIn(this.signInForm.value).subscribe((res: any) => {
        if (res && !res.errorCode) {
          console.log('success', res);
          if(res.role === "CUSTOMER"){
            this.errMsg = "You dont have permissions to login.";
            return;
          }else
          if (res.branches?.length > 1 && res.role === 'Branch Manager') {
            this.router.navigate(['/branch-selection']);
          } else if (res.branches?.length === 0 && res.role === 'Branch Manager') {
            Swal.fire({
              text: 'No branches found for this branch Manager',
              icon: 'error',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            });
          } else if (res.role === 'Staff') {
            this.router.navigate(['/home/profile/my-jobs']).then(() => {
              window.location.reload();
            });
            
          } else if (res.role === 'SERVICE_PROVIDER') {
            this.router.navigate(['/home/profile/manage-branches']).then(() => {
              window.location.reload();
            });
            
          } else {
            this.router.navigate(['/home/planner']).then(() => {
              window.location.reload();
            });
          }
          
        } else {
          console.log('fail');
          this.errMsg = res.message;
        }
      });
    }
  }
  

  isVisible = () => {
    this.showPwdLabel = this.showPwdLabel === 'password' ? 'text' : 'password';
  }

 
}