<div class="d-flex" id="wrapper" *ngIf=" currentRoute !== '/' && currentRoute !== '/sign-in'  && currentRoute !== '/forgot-pin' &&  currentRoute !== '/branch-selection'">
    <app-side-menu>  </app-side-menu>
 
    <div id="page-content-wrapper">
        <!-- <app-loader></app-loader> -->
        <app-header></app-header>  
       
        <app-breadcrumb></app-breadcrumb>


        <div class="container-fluid mt-3">
            <router-outlet></router-outlet>
        </div>
       

    </div>

</div>



<div class="d-flex" id="wrapper" *ngIf="currentRoute === '/' || currentRoute === '/sign-in'  || currentRoute === '/forgot-pin' || currentRoute === '/branch-selection'">
    <div class="container-fluid">
        <router-outlet></router-outlet>
    </div>
</div>