<div class="bg-color-of-pages">
    <!-- <div class="container">
        <div class="breadcrumb-data">
            <li class="breadcrumb-item"  [routerLink]="'/home'">Home</li>
            <li class="breadcrumb-item">Get Started</li>
        </div>
    </div> -->
    <div class="container resgiter-your-business-container">
        <div class="registrations-steps-data">
            <div class="step-item">
                <div class="step-active completed-status" *ngIf="showTab1CompleteIcon">
                    <div class="icon-block">
                        <div class="steps-done">
                            <img src="assets/icons/register/check-white.svg">
                        </div>
                        <img src="assets/icons/register/briefcase.svg" class="default-img">
                    </div>
                </div>
                <div class="icon-block" *ngIf="!showTab1CompleteIcon" [ngClass]="{'circle-active' : showCategoryTab}">
                    <div class="steps-done">
                        <img src="assets/icons/register/check-white.svg">
                    </div>
                    <img src="assets/icons/register/briefcase.svg" class="default-img">
                </div>
                <div class="content-data">
                    <div class="step-title" [ngClass]="{'green' : showTab1CompleteIcon, 'blue': showCategoryTab}">
                        {{!showTab1CompleteIcon ? 'Step 1/3' : 'Completed'}}</div>
                    <div class="info-title">Choose Category</div>
                </div>
            </div>

            <div class="step-item">
                <div class="step-active completed-status" *ngIf="showTab2CompleteIcon">
                    <div class="icon-block" [ngClass]="{'circle-active' : showBusinessTab}">
                        <div class="steps-done">
                            <img src="assets/icons/register/check-white.svg">
                        </div>
                        <img src="assets/icons/register/user.svg" class="default-img">
                    </div>
                </div>
                <div class="icon-block" *ngIf="!showTab2CompleteIcon" [ngClass]="{'circle-active' : showBusinessTab}">
                    <div class="steps-done">
                        <img src="assets/icons/register/check-white.svg">
                    </div>
                    <img src="assets/icons/register/user.svg" class="default-img">
                </div>
                <div class="content-data">
                    <div class="step-title" [ngClass]="{'green' : showTab2CompleteIcon, 'blue' : showBusinessTab}">
                        {{!showTab2CompleteIcon ? 'Step 2/3' : 'Completed'}}</div>
                    <div class="info-title">Business Details</div>
                </div>
            </div>
            <div class="step-item">
                <div class="icon-block" [ngClass]="{'circle-active' : showSuccessTab}">
                    <div class="steps-done">
                        <img src="assets/icons/register/check-white.svg">
                    </div>
                    <img src="assets/icons/register/smile.svg" class="default-img">
                </div>
                <div class="content-data">
                    <div class="step-title" [ngClass]="{'blue' : showSuccessTab}">Step 3/3</div>
                    <div class="info-title">Confirmation</div>
                </div>
            </div>
        </div>

        <div class="steps-tabs-container-data">
            <div class="steps-inputs-data" *ngIf="showCategoryTab">
                <div class="main-heading">Choose your business category.</div>
                <div class="info-title">please choose your business category so that we could get more <br />information
                    about your business</div>
                <div class="popular-services" *ngIf="serviceCategoryList">
                    <div class="inner-padding" *ngFor="let cat of serviceCategoryList">
                        <input type="radio" class="radio-active" name="cat" id="{{cat.title}}" (click)="cardClick(cat)" [checked]="cat.business_category_id === businessCatId" [value]="cat.business_category_id"/>
                        <div class="card-service-p">
                            <span class="checked-item">
                                <i class="bi bi-check-circle-fill"></i>
                            </span>
                            <div class="img-block">
                                <img [src]="cat.image">
                            </div>
                            <div class="service-title" title="{{cat.title}}">{{cat.title}}</div>
                        </div>
                    </div>
                </div>
                <div class="protip-data">We charge 10% commission on bookings.<br />10% a lot? You can negotiate
                    with our saled team? <a target="_blank" [routerLink]="'/contact-us'">Conatct Us</a></div>
                <div class="col-12 p-0 btn-continue">
                    <button class="sb-primary-btn col-12 col-sm-4 col-md-3 col-lg-2"
                        (click)="onContinueFn('tab-1')"  type="button">Continue</button>
                </div>
            </div>
            <div class="steps-inputs-data" *ngIf="showBusinessTab">
                <form [formGroup]="registerYourBusinessForm" (ngSubmit)="onSubmit($event)">
                    <div class="main-heading">To get started, Let us know about your company.</div>
                    <div class="info-title">Please fill in the below details so that we could get in touch with you
                        company.
                    </div>
                    <div class="upload-log-sec col-md-10 col-xs-12 col-lg-10 col-12 pl-0 pr-1">
                        <div class="title-text">Company Logo</div>
                        <!-- <div class="drog-and-drop-upload-logo">
                        <input type="file" id="fileInput">
                        <i class="bi bi-cloud-arrow-up-fill"></i>
                        <div class="title">Drag & Drop</div>
                        <div class="info-kb">(Recommended size : 1080pX1080p)</div>
                    </div> -->

                        <div class="file-area">
                            <form [formGroup]="myForm">
                                <input formControlName="file" id="file" type="file" multiple accept=".png"
                                    class="form-control" (change)="onFileChange($event)">
                                <div class="file-dummy">
                                    <i class="bi bi-cloud-arrow-up-fill"></i>
                                    <div class="title-drog-drop">Drag & Drop</div>
                                    <div class="success">
                                        <span class="file-name">{{fileName}}</span>
                                        <span class="remove" (click)="removefile()" *ngIf="fileName">Remove</span>
                                    </div>
                                    <div class="default">(Recommended size : 256pxX256px)</div>
                                </div>
                            </form>
                        </div>
                        <span class="error-text" *ngIf="formSubmited &&  !fileName">Company Logo required</span>
                    </div>
                    <div class="inputs-block row">
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">Business Name(Legal Name)<sup>*</sup></div>
                            <input type="" class="form-input" placeholder="Business Name(Legal Name)"
                                formControlName="legal_name">
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.legal_name.errors?.required">Business
                                Name(Legal Name) required</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">Contact Name<sup>*</sup></div>
                            <input type="" class="form-input" placeholder="Contact Name" formControlName="name">
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.name.errors?.required">Contact Name
                                required</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">Phone<sup>*</sup></div>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">{{countryCode}}</div>
                                </div>
                                <input type="" class="form-input" placeholder="Phone Number" formControlName="mobile">
                            </div>
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.mobile.errors?.required">Phone
                                required</span>
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.mobile.errors?.pattern || regYourBusinessFormControls.mobile.errors?.maxlength">Phone Number
                                requires minimum 10 Characters</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">EMAIL<sup>*</sup></div>
                            <input type="" class="form-input" placeholder="Email" formControlName="email">
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.email.invalid">EMAIL
                                required</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">Website</div>
                            <input type="url" class="form-input" placeholder="Website" formControlName="website">
                        </div>
                        <div class="address-details col-12">Address Details</div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">Address<sup>*</sup></div>
                            <input type="" class="form-input" placeholder="Address" formControlName="address">
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.address.errors?.required">Address
                                required</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">Landmark<sup>*</sup></div>
                            <input type="" class="form-input" placeholder="Landmark" formControlName="landmark">
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.landmark.errors?.required">Landmark
                                required</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">City<sup>*</sup></div>
                            <input type="" class="form-input" placeholder="City" formControlName="city">
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.city.errors?.required">City
                                required</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="label-text">{{pinCodeLabelName}}<sup>*</sup></div>
                            <input type="" class="form-input" [placeholder]="pinCodeLabelName" formControlName="pincode">
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.pincode.errors?.required">{{pinCodeLabelName}}
                                required</span>
                            <span class="error-text"
                                *ngIf="formSubmited && regYourBusinessFormControls.pincode.errors?.pattern">{{pinCodeLabelName}}
                                requires minimum 6 Characters</span>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="sb-dropdonws" ngbDropdown>
                                <div class="label-text">Country</div>
                                <button class="dropdown-input" ngbDropdownToggle  type="button">{{selectedCountry}}</button>
                                    <ul class="dropdown-menu" ngbDropdownMenu>
                                        <li ngbDropdownItem *ngFor="let country of countryList" (click)="ChangeStateCountry(country, 'country')">
                                            <a class="dropdown_item">{{country}}</a>
                                        </li>
                                    </ul>
                            </div>
                        </div>
                        <div class="column-inputs col-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="sb-dropdonws" ngbDropdown #d="ngbDropdown">
                                <div class="label-text">State</div>
                                <button class="dropdown-input" ngbDropdownToggle  type="button">{{selectedState}}</button>
                                <ul class="dropdown-menu" ngbDropdownMenu>
                                    <li ngbDropdownItem *ngFor="let state of stateList" (click)="ChangeStateCountry(state, 'state')">
                                        <a class="dropdown_item">{{state}}</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <span class="error-text" *ngIf="errMsg">{{errMsg}}</span>
                        <div class="col-12 btn-continue">
                            <button class="sb-back-btn mr-2 col-12 col-sm-4 col-md-3 col-lg-2"
                                (click)="onBackFn('tab-2')" type="button">Back</button>
                            <button class="sb-primary-btn col-12 col-sm-4 col-md-3 col-lg-2"
                                type="submit">Continue</button>
                            <!-- (click)="onContinueFn('tab-2')" -->
                        </div>
                    </div>
                </form>
            </div>
            <div class="last-step-success" *ngIf="showSuccessTab">
                <div class="thanks-page-container-data">
                    <div class="thanks-message-info">
                        <div class="img-block">
                            <img src="assets/icons/success-gif.gif">
                        </div>
                        <div class="content-block">
                            <div class="title">Thank You! Please check your email.</div>
                            <div class="info-text">We have sent a random password to your email ID, you can login with
                                your random password and you can reset it after login.</div>
                            <div class="back-link" [routerLink]="'/sign-in'">Login</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>