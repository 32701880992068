import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })

export class StorageService {
  set(arg0: string, arg1: string) {
    throw new Error('Method not implemented.');
  }
  get(arg0: string) {
    throw new Error('Method not implemented.');
  }
  private storage: Storage;

  constructor() {
    this.storage = sessionStorage;
  }

  clearAll() {
    this.storage.clear();
  }

  isAuthenticated() {
    return this.storage.getItem('user_info') != null ? true : false;
  }

  setItem(key: string, value: any) {
    var data: any = value;
    if (typeof value == 'object') {
      data = JSON.stringify(value);
    }
    this.storage.setItem(key, data);
  }

  getItem(key: string) {
    return this.storage.getItem(key);
  }

  removeItem(key: string) {
    return this.storage.removeItem(key);
  }

}
