<div class="thanks-page-container-data">
    <app-breadcrumb></app-breadcrumb>
    <div class="container">
        <div class="thanks-message-info">
            <div class="img-block">
                <img src="assets/icons/cancel.svg">
            </div>
            <div class="content-block">
                <div class="title text-danger">Payment Failed</div>
                <div class="booking-title text-primary">Booking ID {{orderId}}</div>
                <div class="info-text">Payment failed, click to 
                    <div class="back-link" [routerLink]="'/home/planner/create-service-request'">Retry</div>
                </div>
            </div>
        </div>
    </div>
</div>
