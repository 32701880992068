import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 10
      }
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [
    DataLabelsPlugin
  ];

  public barChartData: ChartData<'bar'> = {
    labels: [ '1', '2', '3', '4', '5', '6', ],
    datasets: [
      { data: [ 16, 12, 17, 21, 14, 24, 18 ], label: 'Cancelled' },
      { data: [ 65, 83, 97, 62, 24, 72, 58 ], label: 'Completed' },
    ]
  };

  public ChartData: ChartData<'bar'> = {
    labels: [ '1', '2', '3', '4', '5', '6', ],
    datasets: [
      { data: [ 1600, 1200, 1700, 2100, 1400, 2400, 1800 ], label: 'Loss' },
      { data: [ 6500, 8300, 9700, 6200, 2400, 7200, 5800 ], label: 'Profit' },
    ]
  };
  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    console.log(event, active);
  }

  public randomize(): void {
    // Only Change 3 values
    this.barChartData.datasets[0].data = [
      Math.round(Math.random() * 100),
      59,
      80,
      Math.round(Math.random() * 100),
      56,
      Math.round(Math.random() * 100),
      40 ];

    this.chart?.update();
  }


  constructor() { }

  ngOnInit(): void {
  }

}
