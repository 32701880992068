<div class="container" id="settings-main-page-wrapper">
    <!-- New tabs design -->
    <div class="form-data-profile mb-5">

        <tabset #staticTabs>
            <tab *ngIf="role !== 'SERVICE_PROVIDER'">
                <ng-template tabHeading>
                    <div class="temp-one">
                        <div class="tab-content-one">
                            <b class="service-content">Weekly Schedule</b>
                        </div>
                    </div>
                </ng-template>

                <div class="nav-tab-content">
                    <!--  -->
                    <div class="mt-2" id="inner-form-data">

                        <div class="form-data-profile">

                            <div class="lesson-form-row mt-4">
                                <div class="form-group frst-branch" *ngFor="let obj of  timerArray">
                                    <div class="toogle-side">

                                        <div class="days">
                                            <p>{{obj.weekName}}</p>
                                        </div>

                                        <div class="time-log">
                                            <timepicker [(ngModel)]="obj.openTime" 
                                                [ngModelOptions]="{standalone: true}">
                                            </timepicker>
                                        </div>
                                        <div class="to-text">
                                            <p>to</p>
                                        </div>

                                        <div class="time-log" id="right-sec-to">
                                            <timepicker [(ngModel)]="obj.closingTime" 
                                                [ngModelOptions]="{standalone: true}">
                                            </timepicker>
                                        </div>


                                        <div class="toggle">
                                            <label class="switch">
                                                <input type="checkbox" [(ngModel)]="obj.isOpen"
                                                    [ngModelOptions]="{standalone: true}">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <button type="submit" class="save-branch" (click)="onSubmit($event)">Save</button>
                    </div>
                    <!--  -->
                </div>
            </tab>

            <tab *ngIf="role !== 'SERVICE_PROVIDER'">
                <ng-template tabHeading>
                    <div class="temp-one" (click)="showHolidayList=true;showCreateHoliday=false;getHolidayList();">
                        <div class="tab-content-one">
                            <b class="service-content">Holidays</b>
                        </div>
                    </div>
                </ng-template>
                <div class="nav-tab-content">
                    <!--  -->
                    <div class="mt-4" id="inner-form-data">
                        <!---------------------------new design-->
                        <div class="container" *ngIf="showHolidayList">
                            <div class="card-body server-datatable selection-datatable">


                                <div class="add-holiday-wrapperr">
                                    <div class="holiday-search-wrapper">
                                        <div class="search_form">
                                            <div class="form-group">
                                                <input type="submit" class="form-control" #search type="search"
                                                    placeholder="Search" [(ngModel)]="searchKeyword">
                                            </div>
                                            <div class="search">
                                                <button type="submit" class="filled  mb-4"
                                                    (click)="getHolidayList()">Search</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="add-new-holiday-wrappe">
                                        <button (click)="gotoCreateHoliday()" type="button" class="add-staff">
                                            <img src="assets/icons/plus-small.svg" alt=""><span class="a-staff">Add
                                                Holiday</span>
                                        </button>
                                    </div>

                                </div>

                                <div class="top_wrapper">
                                    <!-- <button (click)="gotoCreateHoliday()" type="button" class="add-staff">
                                        <img src="assets/icons/plus-small.svg" alt=""><span class="a-staff">Add Holiday</span>
                                    </button> -->
                                </div>

                                <!-- <div class="search_form">
                                    <div class="form-group">
                                        <input type="submit" class="form-control" #search type="search" placeholder="Search">
                                    </div>
                                    <div class="search">
                                        <button type="submit" class="filled  mb-4">Search</button>
                                    </div>
                                </div> -->


                                <div class="tbl-hold">

                                    <table>
                                        <p-table [value]="holidayList" responsiveLayout="scroll" [rows]="10">
                                            <ng-template pTemplate="header">

                                                <tr>
                                                    <th>Name</th>
                                                    <th>Date</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-hDay>
                                                <tr>
                                                    <td>{{hDay.name}}</td>
                                                    <td>{{hDay.date}}</td>
                                                    <td>
                                                        <!-- Action Buttons -->
                                                        <img src="assets/images/edit-circle.svg" alt="Edit Icon"
                                                            title="Click to Edit Staff" style="cursor: pointer;"
                                                            (click)="gotoEditHoliday(hDay)">&nbsp;&nbsp;&nbsp;&nbsp;

                                                        <img src="assets/images/delete-black.svg" alt="View Icon"
                                                            title="Click for Delete Staff" style="cursor: pointer;"
                                                            (click)="deleteHoliday(hDay?.holiday_id)">
                                                        <!-- Action Buttons -->
                                                    </td>
                                                </tr>

                                            </ng-template>
                                        </p-table>
                                    </table>
                                </div>

                                <div class="text-center" *ngIf="holidayList?.length == 0">
                                    <div class="container no-data-wrapper text-center">
                                        <div class="empty-cart-img text-center">
                                            <img src="assets/images/No data-rafiki.svg" alt="No data available">
                                        </div>
                                        <div class="text-center no-data-text">No data available</div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="totalCount > 0">
                                    <div class="col align-self-end">
                                        <p-paginator [rows]="10" [totalRecords]="totalCount"
                                            (onPageChange)="paginate($event)">
                                        </p-paginator>
                                    </div>
                                </div>

                                <app-loader></app-loader>
                            </div>
                        </div>

                        <!-----------------new-design--code--------------->

                        <div class="container add-holiday-wrapper" *ngIf="showCreateHoliday">
                            <div class="card-body">
                                <span class="angle-left"><i class="fi fi-rr-angle-left"></i></span><span
                                    class="gotoback" (click)="onCancel()">&nbsp; Back</span>
                                <h5 class="c-holiday mt-4">Create Holiday</h5>

                                <form [formGroup]="addHolidayForm" (ngSubmit)="onHolidaySubmit($event)"
                                    id="select-service-category">

                                    <div class="row mt-4">
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <label class="control-label" for="firstname">Name <span
                                                        class="manstar">*</span></label>
                                                <input type="text" class="form-control form-input-border" id="firstname"
                                                    placeholder="Ex:Abdul" name="firstname" maxlength="30"
                                                    formControlName="holidayName">
                                                <span class="error-text"
                                                    *ngIf="showHolidayformSubmited && addHolidayFormControls.holidayName.errors?.required">Name
                                                    required</span>
                                                <span class="error-text"
                                                    *ngIf="showHolidayformSubmited && addHolidayFormControls.holidayName.errors?.maxlength">Name
                                                    requires minimum 10 Characters</span>
                                            </div>
                                        </div>

                                        <div class="col-md-5">
                                            <div class=" form-group">
                                                <label class="control-label" for="mobile">Date<span
                                                        class="required-symbol">*</span></label>
                                                <p-calendar class="pp-call" formControlName="holidayDate"
                                                    [showIcon]="true" id="date-change" inputId="icon1"
                                                    placeholder="From Date" [readonlyInput]="true" [minDate]="maxDate">
                                                </p-calendar>
                                                <span class="error-text"
                                                    *ngIf="showHolidayformSubmited && addHolidayFormControls.holidayDate.errors?.required">Date
                                                    required</span>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <button type="submit" class="h-save-btn" id="next-step-button">Save</button>
                                        </div>

                                    </div>

                                    <!-- <div class="button-wrapper mb-5">
                                                <button type="button" class="btn" id="cancel-button" (click)="onCancel()">Back</button>
                                                <button type="submit" class="btn" id="next-step-button">Save</button>
                                            </div> -->


                                </form>

                            </div>
                        </div>
                    </div>
                    <!--  -->
                </div>
            </tab>
            <!------------------profile-->
            <tab *ngIf="role !== 'SERVICE_PROVIDER'">
                <ng-template tabHeading>
                    <div class="temp-one">
                        <div class="tab-content-one">
                            <b class="service-content">Gallery</b>
                        </div>
                    </div>
                </ng-template>
                <div class="nav-tab-content">
                    <div class="mt-2" id="inner-form-data">
                        <div class="form-group">
                            <div class="col-md-12 mt-5" *ngIf="branchImagesList?.length === 0">
                                <div class="form-group gallary-upload">
                                    <div class="custom-dropzone" ngx-dropzone [accept]="'.jpg, .png'"
                                        [expandable]="true" (change)="onFileChange($event)">
                                        <ngx-dropzone-label>
                                            <!-- <img src="assets/icons/add file svg.svg" class="upload-img" alt="">
                                            <h5 class="mt-3 mb-2 drag-text-inner">Drag and Drop</h5>
                                            or<br>
                                            <p class="drop-text"> Browse your files</p> -->
                                            <img src="assets/icons/file.png" class="upload-img" alt="">
                                            <h5 class="mt-3 mb-2 drag-text-inner">Drag and Drop, Or<b class="drop-text">
                                                    Browse</b> your files.</h5>
                                            <p class="accepted-format-text">( Only JPG , PNG files with max size of 2 MB
                                                )</p>
                                            <!-- <p class="accepted-format-text">( Only JPG , PNG files with max size of 2 MB )</p> -->
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-image-preview #nationalDropZone ngProjectAs="ngx-dropzone-preview"
                                            *ngFor="let f of fileList" [file]="f" [removable]="true"
                                            (removed)="onRemove(f)"></ngx-dropzone-image-preview>
                                        <!-- <img [src]="imgURL" width="120" height="120" *ngIf="+serviceId !== +-1 && !fileName"/> -->
                                    </div>
                                </div>
                            </div>
                            <div class="text-right mt-4 mb-4">
                                <button type="submit" class="btn-upload" (click)="onUpload()"><span><img
                                            src="assets/images/cloud-upload.svg" alt=""> </span> <span
                                        class="">&nbsp;&nbsp;Upload</span></button>
                            </div>



                            <!-- Gallery Main Page Wrapper -->
                            <div class="gallery-main-wrapper">
                                <div class="gallery-inner-item mt-2" *ngFor="let document of branchImagesList">
                                    <div class="image-wrapper">
                                        <img [src]="document" [alt]="imgPreview">
                                    </div>
                                    <div class="action-icons mt-2">
                                        <a (click)="imgPreview='';showPreview = true;imgPreview=document;"><img
                                                class="svg2" src="assets/icons/eye.svg" alt=""></a>&nbsp;
                                        <a (click)="onImageDelete(document)"><img class="svg2"
                                                src="assets/icons/trash.svg" alt=""></a>
                                    </div>
                                </div>
                            </div>
                            <!-- Gallery Main Page Wrapper -->


                            <!-- <p-table [value]="branchImagesList" [rows]="12" class="mt-3 d-none" *ngIf="branchImagesList?.length > 0">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>ACTIONS</th>
                                        <th>Images</th>
                                    </tr>
                                </ng-template>
                                
                                <ng-template pTemplate="body" let-document let-i="rowIndex">
                                    <tr>

                                        <div class="row">
                                            <div class="col-md-4">
                                                <td>
                                                    <a (click)="imgPreview='';showPreview = true;imgPreview=document;"><img class="svg2" src="assets/icons/eye.svg" alt=""></a>&nbsp;
                                                    <a (click)="onImageDelete(document)"><img class="svg3" src="assets/icons/trash.svg" alt=""></a>
                                                    <img [src]="document" [alt]="imgPreview" width="100%" height="100%" />
                                                </td>
                                            </div>

                                        </div>
                                        
                                        
                                        <td>
                                            <img [src]="document" [alt]="imgPreview" width="100%" height="100%" />
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table> -->
                        </div>
                    </div>
                </div>




                <div class="form-group d-none">

                    <div class="col-md-12 mt-5">
                        <div class="form-group gallary-upload">
                            <!-- <label class="control-label" id="form-label-color">Upload Image
                        <span class="required-symbol">*</span>
                    </label> -->
                            <div class="custom-dropzone" ngx-dropzone [accept]="'.jpg, .png'" [expandable]="true"
                                (change)="onFileChange($event)">
                                <ngx-dropzone-label>
                                    <div>
                                        <img src="assets/icons/add file svg.svg" class="upload-img" alt="">
                                        <h5 class="mt-3 mb-2 drag-text-inner">Drag and Drop</h5>
                                        or<br>
                                        <p class="drop-text"> Browse your files</p>
                                        <!-- <p class="accepted-format-text">( Only JPG , PNG files with max size of 2 MB )</p> -->
                                    </div>
                                </ngx-dropzone-label>

                                <ngx-dropzone-image-preview #nationalDropZone ngProjectAs="ngx-dropzone-preview"
                                    *ngFor="let f of fileList" [file]="f" [removable]="true" (removed)="onRemove(f)">
                                </ngx-dropzone-image-preview>
                                <!-- <img [src]="imgURL" width="120" height="120" *ngIf="+serviceId !== +-1 && !fileName"/> -->
                            </div>
                            <span class="error-text" *ngIf="formSubmited && imgURL === ''">Upload Image required</span>

                        </div>

                    </div>
                    <span class="error-text" *ngIf="formSubmited && imgURL === ''">Upload Image required</span>
                </div>
                <!-- <button  type="submit" class="btn-upload"> Upload</button> -->




                <!------------------->
            </tab>

            <tab *ngIf="role === 'SERVICE_PROVIDER'">
                <ng-template tabHeading>
                    <div class="temp-one">
                        <div class="tab-content-one">
                            <b class="service-content">Deposit</b>
                        </div>
                    </div>
                </ng-template>
                <div class="nav-tab-content">
                    <!--  -->
                    <div class="mt-4" id="inner-form-data">
                        <!---------------------------new design-->

                        <!-----------------new-design--code--------------->

                        <div class="container add-holiday-wrapper">
                            <div class="card-body">
                               
                                <!-- <h4 class="c-holiday mt-4">Select Type of Deposit</h4> -->

                                  
                                  <!-- <div class="radio-data-block mb-2">
                                        <div class="radio-custom-sb">
                                            <input type="radio" [(ngModel)]="depositType" value="Percentage" id="Percentage" class="radio-custom">
                                            <label for="male" class="radio-custom-label">Percentage %</label>
                                        </div>
                                 </div> -->
                                 <!-- <div class="radio-data-block">
                                    <div class="radio-custom-sb">
                                        <input type="radio" [(ngModel)]="paymentType" value="Female" id="female" class="radio-custom">
                                        <label for="female" class="radio-custom-label">Female</label>
                                    </div>
                                </div> -->

                                

                                <div class="col-md-4 mb-5">
                                    <div class="form-group">
                                        <h4 class="c-holiday mt-4">Enter Percentage % Amount</h4>
                                        <input type="number" class="form-control form-input-border" id="firstname"
                                             name="firstname"  placeholder="eg 52 %" [(ngModel)]="depositAmt">
                                    </div>
                                </div>

                                <button type="submit" class="btn-upload me-1 mt-2" (click)="saveDeposit()">Save</button>

                            </div>
                        </div>
                    </div>
                    <!--  -->
                </div>
            </tab>

        </tabset>


        <!-- New tabs design -->
    </div>

    <p-dialog [(visible)]="showPreview">
        <img [src]="imgPreview" [alt]="imgPreview" width="100%" height="100%" />
    </p-dialog>


    <div id="upload_dialog_box">

        <p-dialog [(visible)]="display">
            <h3 class=" .att_documents mt-5">Attach Images</h3>
            <div class="custom-dropzone" ngx-dropzone [accept]="'.jpg, .png'" [expandable]="true"
                (change)="onFileChange($event)" [multiple]="false">
                <ngx-dropzone-label>
                    <div>
                        <img src="assets/icons/upload-doc.svg" alt="">
                        <div class="file_Sentence">
                            <h4 class="drag_File">Drag & Drop here</h4>

                            <p class="or">or</p>
                            <p class="brows_files">Browse files</p>
                        </div>
                    </div>
                </ngx-dropzone-label>

                <ngx-dropzone-image-preview #nationalDropZone ngProjectAs="ngx-dropzone-preview"
                    *ngFor="let f of fileList" [file]="f" [removable]="true" (removed)="onRemove(f)">
                </ngx-dropzone-image-preview>
            </div>

            <p class="docr">Accepted File Types: jpg and png only</p>


            <button type="submit" class="upload_btn" (click)="uploadDocuments()">Upload</button>
        </p-dialog>
    </div>