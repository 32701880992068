<div class="container" id="main-container">
  <div class="heading">
    <p>COMPLETED SERVICES</p>
  </div>
  <p-accordion>
    <p-accordionTab header="Today" [selected]="true">
      <div class="col-md-12 mt-3 pt-3">
        <div class="header-text">
          <div class="text-center"> ORDER ID </div>
          <div>CUSTOMERS</div>
          <div>SERVICE</div>
          <div>AMOUNT</div>
          <div> ASSIGNED TO</div>
          <div>STATUS</div>

        </div>
        <div class="example-col mt-3">
          <div id="service-data"> Eg: 2234 </div>
          <div id="service-data">Eg: John Doe</div>
          <div id="service-data">Eg : Haircut</div>
          <div id="service-data">Eg : ₹620</div>
          <div id="service-data"> Eg : 20</div>
          <div id="service-data">Eg : 20</div>
        </div>

        <div class="col-md-12 mt-3" *ngFor="let service of serviceList">
          <div class="row">
            <div class="col-md-2 text-center"> {{service.service_request_id}}</div>
            <div class="col-md-2">
              <p>{{service.customer.user.first_name + '' + service.customer.user.last_name}}</p>
              <p class="john-line"> {{service.customer.user.email}}</p>
            </div>
            <div class="col-md-6">
              <div class="row" *ngFor="let sObj of service.services">
                <div class="col-md-3 john-line"> {{sObj.service.title}}</div>
                <div class="col-md-3 john-line">₹{{service.amount}}</div>
                <div class="col-md-2 john-line">{{sObj.schedule_time}} </div>
                <div class="col-md-4 john-line">{{sObj.schedule_date}}</div>
              </div>
            </div>
            <!-- <div class="col-md-2"> <button type="button" class="btn btn-danger">Reject All</button></div> -->
            <div class="col-md-2">
      
              <div class="menu-nav">
                <div class="dropdown-container" tabindex="-1">
                  <div class="three-dots"></div>
                  <div class="dropdown">
                    <a (click)="gotoServiceDetails(service)"><img src="assets/icons/eye.svg" alt=""> &nbsp; &nbsp;View Details</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-12">
          <div class="row">
            <div class="col-md-2 text-center"> 1022</div>

            <div class="col-md-2">
              <p>John Carpenter</p>
              <p class="john-line"> john.carpenter@gmail.com</p>
            </div>
            <div class="col-md-2">Haircut</div>
            <div class="col-md-1">₹620</div>
            <div class="col-md-1">Niketan Kumar</div>

            <div class="col-md-2"><button type="button" class="btn btn-completed">Completed</button></div>
            <div class="col-md-2">

              <div class="menu-nav">
                <div class="dropdown-container" tabindex="-1">
                  <div class="three-dots"></div>
                  <div class="dropdown">
                    <a href="#"><img src="assets/icons/assign-user-svgrepo-com.svg" alt=""> &nbsp; &nbsp;Assign</a>
                    <a href="#"><img src="assets/icons/x.svg" alt=""> &nbsp; &nbsp;Reject Services</a>
                    <a href="#"><img src="assets/icons/eye.svg" alt=""> &nbsp; &nbsp;View Details</a>
                    <a href="#"><img src="assets/icons/edit-3.svg" alt=""> &nbsp; &nbsp;Edit</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <div class="row">
            <div class="col-md-2 text-center"> 1022</div>
            <div class="col-md-2">
              <p>John Carpenter</p>
              <p class="john-line"> john.carpenter@gmail.com</p>
            </div>
            <div class="col-md-2">Beard Shave</div>
            <div class="col-md-1">₹620</div>
            <div class="col-md-1">Priya Kumari</div>
            <div class="col-md-2"><button type="button" class="btn btn-completed">Completed</button></div>
            <div class="col-md-2">

              <div class="menu-nav">
                <div class="dropdown-container" tabindex="-1">
                  <div class="three-dots"></div>
                  <div class="dropdown">
                    <a href="#"><img src="assets/icons/assign-user-svgrepo-com.svg" alt=""> &nbsp; &nbsp;Assign</a>
                    <a href="#"><img src="assets/icons/x.svg" alt=""> &nbsp; &nbsp;Reject Services</a>
                    <a href="#"><img src="assets/icons/eye.svg" alt=""> &nbsp; &nbsp;View Details</a>
                    <a href="#"><img src="assets/icons/edit-3.svg" alt=""> &nbsp; &nbsp;Edit</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <div class="row">
            <div class="col-md-2 text-center"> 1022</div>
            <div class="col-md-2">
              <p>John Carpenter</p>
              <p class="john-line"> john.carpenter@gmail.com</p>
            </div>
            <div class="col-md-2">Body Massage</div>
            <div class="col-md-1">₹620</div>
            <div class="col-md-1">Priya Kumarir</div>
            <div class="col-md-2"><button type="button" class="btn btn-completed">Completed</button></div>
            <div class="col-md-2">

              <div class="menu-nav">
                <div class="dropdown-container" tabindex="-1">
                  <div class="three-dots"></div>
                  <div class="dropdown">
                    <a href="#"><img src="assets/icons/assign-user-svgrepo-com.svg" alt=""> &nbsp; &nbsp;Assign</a>
                    <a href="#"><img src="assets/icons/x.svg" alt=""> &nbsp; &nbsp;Reject Services</a>
                    <a href="#"><img src="assets/icons/eye.svg" alt=""> &nbsp; &nbsp;View Details</a>
                    <a href="#"><img src="assets/icons/edit-3.svg" alt=""> &nbsp; &nbsp;Edit</a>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div> -->
      </div>
    </p-accordionTab>
  </p-accordion>
</div>