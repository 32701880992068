import { Component, NgModule } from '@angular/core';
import { RouterModule, ROUTER_CONFIGURATION, ROUTES, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';

import { RouteConstants } from './shared/route-constants';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { CompletedServicesComponent } from './planner/completed-services/completed-services.component';
import { BranchSheduleComponent } from './components/branch-shedule/branch-shedule.component';
import { SalesReportsComponent } from './components/sales-reports/sales-reports.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { RegisterYourBusinessComponent } from './components/register-your-business/register-your-business.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { ReportsComponent } from './profile/reports/reports.component';
import { SettingsComponent } from './profile/settings/settings.component';
import { PricingPageComponent } from './components/pricing-page/pricing-page.component';
import { ManageInventoryComponent } from './manage-inventory/manage-inventory.component';
import { PointOfSaleComponent } from './point-of-sale/point-of-sale.component';
import { PaymentFailComponent } from './payment-fail/payment-fail.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
// import { AddressListComponent } from './planner/address-list/address-list.component';

const routes: Routes = [
  {
    path: '',
    // component: SignInComponent,
    redirectTo: RouteConstants.SIGNIN,
    pathMatch: 'full'
  },
  {
    path: RouteConstants.SIGNIN,
    component: SignInComponent
  },
  {
    path: RouteConstants.FORGOTPIN,
    component: ForgotPasswordComponent
  },

  {
    path: RouteConstants.HOME_PAGE,
    component: DashboardComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      },

      {
        path: RouteConstants.DASHBOARD,
        component: HomeComponent
      },
      {
        path: RouteConstants.REGISTERYOURBUSINESS,
        component: RegisterYourBusinessComponent
      },

      {
        path: RouteConstants.REPORTS,
        component: ReportsComponent
      },
      {
        path: RouteConstants.SETTINGS,
        component: SettingsComponent
      },
      {
        path: RouteConstants.PROFILE,
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: RouteConstants.STAFF,
        loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule)
      },
      {
        path: RouteConstants.USER,
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },

      {
        path: RouteConstants.MANAGEINQUIRIES,
        loadChildren: () => import('./manage-inquiries/manage-inquiries.module').then(m => m.ManageInquiriesModule)
      },
      {
        path: RouteConstants.CUSTOMERINVOICE,
        loadChildren: () => import('./customer-invoice/customer-invoice.module').then(m => m.CustomerInvoiceModule)
      },
      {
        path: RouteConstants.CUSTOMERS,
        loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule)
      },
      {
        path: RouteConstants.MANAGECATEGOTY,
        loadChildren: () => import('./manage-category/manage-category.module').then(m => m.ManageCategoryModule)
      },
      {
        path: RouteConstants.EXPENSES,
        loadChildren: () => import('./expenses/expenses.module').then(m => m.ExpensesModule)
      },
      {
        path: RouteConstants.MANAGESERVICE,
        loadChildren: () => import('./manage-service/manage-service.module').then(m => m.ManageServiceModule)

      },
      {
        path: RouteConstants.CUSTOMERINVOICEDETAILS,
        loadChildren: () => import('./customer-invoice-details/customer-invoice-details-routing.module').then(m => m.CustomerInvoiceDetailsRoutingModule)
      },
      {
        path: RouteConstants.PLANNER,
        loadChildren: () => import('./planner/planner.module').then(m => m.PlannerModule)
      },
      {
        path: RouteConstants.SERVICEREQUESTHISTORY,
        loadChildren: () => import('./service-request-history/service-request-history.module').then(m => m.ServiceRequestHistoryModule)
      },
      {
        path: RouteConstants.BRANCH_MANAGERS,
        loadChildren: () => import('./branch-mangers/branch-mangers.module').then(m => m.BranchMangersModule)
      },
      {
        path: RouteConstants.PLAN,
        loadChildren: () => import('./plan/plan.module').then(m => m.PlanModule)
      }
      // {
      //   path: RouteConstants.BMLIST,
      //   loadChildren: () => import('./branch-mangers/branch-mangers.module').then(m => m.BranchMangersModule)
      // }
    ]
  },

  // {
  //   path: RouteConstants.ADDBRANCH,
  //   component: AddBranchComponent
  // },

  {
    path: RouteConstants.COMPLETEDSERVICES,
    component: CompletedServicesComponent
  },
  {
    path: RouteConstants.BRANCHSHEDULE,
    component: BranchSheduleComponent
  },
  {
    path: RouteConstants.SALESREPORTS,
    component: SalesReportsComponent
  },
  {
    path: RouteConstants.SUBSCRIPTION,
    component: SubscriptionComponent
  },
  {
    path: RouteConstants.CHANGEPASSWORD,
    component: ChangePasswordComponent
  },
  {
    path: RouteConstants.UPDATEPASSWORD,
    component: UpdatePasswordComponent
  },
  {
    path: RouteConstants.PRICING_PAGE,
    component: PricingPageComponent
  },
  {
    path: RouteConstants.BRANCH_SELECTION,
    loadChildren: () => import('./branch-selection/branch-selection.module').then(m => m.BranchSelectionModule)
  },
  {
    path: RouteConstants.MANAGE_INVENTORY,
    component: ManageInventoryComponent
  },
  {
    path: RouteConstants.POINTOFSALE,
    component: PointOfSaleComponent
  },
  {
    path: RouteConstants.PAYMENT_SUCCESS,
    component: PaymentSuccessComponent
  },
  {
    path: RouteConstants.PAYMENT_FAIL,
    component: PaymentFailComponent
  },
  // {
  //   path: RouteConstants.ADDRESSLIST,
  //   component: AddressListComponent
  // },
  // {
  //   path: '**',
  //   redirectTo: RouteConstants.SIGNIN
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
