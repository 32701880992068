import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-fail',
  templateUrl: './payment-fail.component.html',
  styleUrls: ['./payment-fail.component.scss']
})
export class PaymentFailComponent implements OnInit {

  
  orderId: any;
  constructor(private activatedRoute: ActivatedRoute,  private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.orderId = params.orderId;
      this.cdr.detectChanges();
    });
  }

}
