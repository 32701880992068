<div class="login-container-page row">
    <div class="login-form-section col-lg-6 col-md-6 col-sm-12 col-12">

      <!-- LogIn form -->
      <ng-container *ngIf="true">
        <div class="welcome-heading">Welcome Back!</div>
        <div class="heading-text">Sign In</div>
        <div class="desc-text">Please enter your details</div>
        <form [formGroup]="signInForm" (ngSubmit)="onSubmit($event)">
          <div class="input-block">
            <div class="label-text">EMAIL</div>
            <input type="email" placeholder="Please enter your email" class="input-form" formControlName="email">
            <div class="valid-check">
              <i class="bi bi-check-circle-fill success"  *ngIf="emailControls.valid  && formSubmited"></i>
            </div>
            <span class="error-text" *ngIf="emailControls.invalid  && formSubmited">Email required</span>
          </div>
          <div class="input-block">
            <div class="label-text">PASSWORD</div>
            <input [type]="showPwdLabel" placeholder="Please enter your password" class="input-form" formControlName="pwd">
            <div class="show-pswd">
              <i class="bi bi-eye" (click)="isVisible()" *ngIf="showPwdLabel === 'text'"></i>
              <i class="bi bi-eye-slash"(click)="isVisible()" *ngIf="showPwdLabel === 'password'"></i>
            </div>
            <span class="error-text" *ngIf="pwdControls.invalid  && formSubmited">Password required</span>
          </div>
          <!--  -->
          <!-- <div class="input-block" id="select-branch-signin">
            <div class="label-text">SELECT BRANCH</div>
            <ng-select [items]="selectbranch" bindLabel="name" class="ngg-sel" placeholder="Please select branch" [(ngModel)]="selectedBranch"></ng-select>
          </div> -->
          <!--  -->
          <div class="remebaer-block">
            <label class="check-label-mark">
              <div class="name-label">Remember me</div>
              <input type="checkbox" checked="checked">
              <span class="checkmark"></span>
            </label>
            <div class="forgot-pswd" [routerLink]="['../forgot-pin']">Forgot Password</div>
          </div>
          <span class="error-text" *ngIf="errMsg">{{errMsg}}</span>
          <button class="sb-primary-btn text-white w-100" type="submit">Login</button>
        </form>
      </ng-container>
    </div>
    <div class="info-block-with-img col-lg-6 col-md-6 col-sm-6 col-12">
      <div class="img-block-wrapper position-relative">
        <img src="assets/icons/login-signup/login-bg.png">
        <div class="content-text-data">
          <!-- <div class="title">Want to register your business?</div>
          <button class="sb-primary-btn text-white btn-block" [routerLink]="['../home/register-your-business']">Register</button> -->
        </div>
      </div>
    </div>
</div>
