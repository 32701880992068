import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { throwError } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {


  constructor(private http: HttpClient, private storageService: StorageService) {
  }


  serviceProviderDetails = () => {
    let targetUrl = environment.apiUrl + `serviceprovider/details`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  updateProviderDetails = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `serviceprovider/update`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  updateCustomerAddress(reqObj: any) {
    let targetUrl = environment.apiUrl + `customer/address/update`;
    return this.http.put<any>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getProvidersBranchList = (reqObj: any) => {
    let httpParams = new HttpParams()
    if (reqObj.providerId !== '') {
      httpParams = httpParams.append('provider_slug_name', reqObj.providerId)
    }
    if (reqObj.status !== undefined && reqObj.status !== '') {
      httpParams = httpParams.append('status', reqObj.status)
    }
    if (reqObj.query !== undefined && reqObj.query !== '') {
      httpParams = httpParams.append('query', reqObj.query)
    }
    if (reqObj.page !== undefined && reqObj.page !== '') {
      httpParams = httpParams.append('page', reqObj.page)
    }
    let targetUrl = environment.apiUrl + `branch/by_service_provider`;
    return this.http.get<[]>(targetUrl, { params: httpParams }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  // All Branch List
  getTotalBranchList = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/manager_branchlist/${reqObj.providerId}/${reqObj.branchSlugName}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }
  // 


  addBranch = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  addManager = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/assignnewbranchmanager`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getCategoryListByBranch = (reqObj: any) => {
    let httpParams = new HttpParams()
    if (reqObj.branchId !== '') {
      httpParams = httpParams.append('branch_id', reqObj.branchId)
    }
    if (reqObj.query !== undefined && reqObj.query !== '') {
      httpParams = httpParams.append('query', reqObj.query)
    }
    if (reqObj.page !== undefined && reqObj.page !== '') {
      httpParams = httpParams.append('page', reqObj.page)
    }
    let targetUrl = environment.apiUrl + `service/servicecategorybybranch`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  // ****************************
  saveRegisterYourBusiness = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `serviceprovider/`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getCategories() {
    let targetUrl = environment.apiUrl + 'super_admin/business_category/popular';
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  // ****************************

  cloneService = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `service/clone_services`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  onBranchStatusChange = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/status_change`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getBranchDetails = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/details/${reqObj.providerId}/${reqObj.branchSlugName}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  updateBranch = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/update`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  // updateBranch = (branchId: any) => {
  //   let httpParams = new HttpParams()
  //   if (branchId !== '') {
  //     httpParams = httpParams.append('pslug_title', branchId)
  //   }
  //   let targetUrl = environment.apiUrl + `branch/update`;
  //   return this.http.get<[]>(targetUrl, { params: httpParams }).pipe(
  //     map(response => {
  //       return response
  //     }),
  //     catchError(err => {
  //       return of(err);
  //     }))
  // }
  // 
  // updateBranch(addressId: any) {
  //   let targetUrl = environment.apiUrl + `branch/update`;
  //   let payload={
  //     address_id: addressId,
  //     branch_id: addressId,
  //     branch_manager: addressId
  //   }
  //     return this.http.put<any>(targetUrl, payload).pipe(
  //     map(response => {
  //       return response
  //     }),
  //     catchError(err => {
  //       return of(err);
  //     }))
  // }

  // updateBranchAddress(addressId: any) {
  //   let targetUrl = environment.apiUrl + `branch/address`;
  //   let payload={
  //     address_id:addressId
  //   }
  //     return this.http.put<any>(targetUrl, payload).pipe(
  //     map(response => {
  //       return response
  //     }),
  //     catchError(err => {
  //       return of(err);
  //     }))
  // }

  updateBranchAddress = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/address`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  createServiceCategory = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `service/category`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  updateServiceCategory = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `service/update_category`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  getCategoryDetails = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `service/category/${reqObj.branchId}/${reqObj.catSlugName}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  deleteServiceCategory = (catId: any) => {
    let targetUrl = environment.apiUrl + `service/delete_category/${catId}`;
    return this.http.put<[]>(targetUrl, { categoryId: catId }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  // Delete Expense Record
  deleteExpenseRecord(expenseId: any) {
    let targetUrl = environment.apiUrl + `expense/delete/`;
    let payload = {
      expense_id: expenseId
    }
    return this.http.put<any>(targetUrl, payload).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }
  // 
  // 
  // Update Expense Record
  // updateRecordExpenses(expenseId: any) {
  //   let targetUrl = environment.apiUrl + `expense/update/`;
  //   let payload={
  //     purpose_of_expense:expenseId
  //   }
  //     return this.http.put<any>(targetUrl, payload).pipe(
  //     map(response => {
  //       return response
  //     }),
  //     catchError(err => {
  //       return of(err);
  //     }))
  // }
  // 

  uploadFile = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `upload/file`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  createService = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `service/`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  updateService = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `service/update`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  getServiceDetails = (serviceId: any) => {
    let targetUrl = environment.apiUrl + `service/${serviceId}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getServicesList = (reqObj: any) => {
    let httpParams = new HttpParams()
    if (reqObj.query !== undefined && reqObj.query !== '') {
      httpParams = httpParams.append('query', reqObj.query)
    }
    if (reqObj.page !== undefined && reqObj.page !== '') {
      httpParams = httpParams.append('page', reqObj.page)
    }
    let targetUrl = environment.apiUrl + `service/by_branch/${reqObj.branchId}`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  onServiceStatusChange = (serviceId: any) => {
    let targetUrl = environment.apiUrl + `service/delete/${serviceId}`;
    return this.http.put<[]>(targetUrl, serviceId).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  // deleteServiceCategory = (serviceId: any) => {
  //   let targetUrl = environment.apiUrl + `service/delete/${serviceId}`;
  //   return this.http.put<[]>(targetUrl, serviceId).pipe(
  //     map(response => {
  //       return response
  //     }),
  //     catchError(err => {
  //       return of(err);
  //     }))
  // }



  // onServiceStatusChange(serviceId: any) {
  //   let targetUrl = environment.apiUrl + `service/delete/`;
  //   let payload={
  //     service_id:serviceId
  //   }
  //     return this.http.put<any>(targetUrl, payload).pipe(
  //     map(response => {
  //       return response
  //     }),
  //     catchError(err => {
  //       return of(err);
  //     }))
  // }


  addStaff = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `employee/`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  getStaffList = (reqObj: any) => {
    let httpParams = new HttpParams()
    if (reqObj.query !== undefined && reqObj.query !== '') {
      httpParams = httpParams.append('query', reqObj.query)
    }
    if (reqObj.page !== undefined && reqObj.page !== '') {
      httpParams = httpParams.append('page', reqObj.page)
    }
    let targetUrl = environment.apiUrl + `employee/by_branch/${reqObj.branchId}?role=${reqObj.role}`;
      return this.http.get<[]>(targetUrl, {
        params: httpParams
      }).pipe(
        map(response => {
          return response
        }),
        catchError(err => {
          return of(err);
        }))
  }

  updateStaff = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `employee/update`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  getStaffDetails = (staffId: any) => {
    let targetUrl = environment.apiUrl + `employee/${staffId}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  onStaffStatusChange = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `employee/status_change`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  createCustomer = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `customer/`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  // Delete Admin
  deleteAdminRecord = (employeeId: any) => {
    let targetUrl = environment.apiUrl + `employee/delete/${employeeId}`;
    return this.http.put<[]>(targetUrl, employeeId).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  // Delete Admin
  deleteStaffRecord = (employeeId: any) => {
    let targetUrl = environment.apiUrl + `employee/delete/${employeeId}`;
    return this.http.put<[]>(targetUrl, employeeId).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  getCustomerList = (reqObj: any) => {
    let httpParams = new HttpParams()
    if (reqObj.status !== undefined && reqObj.status !== '') {
      httpParams = httpParams.append('status', reqObj.status)
    }
    if (reqObj.query !== undefined && reqObj.query !== '') {
      httpParams = httpParams.append('query', reqObj.query)
    }
    if (reqObj.page !== undefined && reqObj.page !== '') {
      httpParams = httpParams.append('page', reqObj.page)
    }

    let targetUrl = environment.apiUrl + `customer/list/?provider_id=${reqObj.providerId}`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getCustomerDetails = (customerId: any) => {
    let targetUrl = environment.apiUrl + `customer/${customerId}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  updateCustomer = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `customer/update`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getInquiries = (reqObj: any) => {
    let httpParams = new HttpParams()
    if (reqObj.startDate !== undefined) {
      httpParams = httpParams.append('start', reqObj.startDate)
    }

    if (reqObj.endDate !== undefined) {
      httpParams = httpParams.append('end', reqObj.endDate)
    }
    if (reqObj.page !== undefined && reqObj.page !== '') {
      httpParams = httpParams.append('page', reqObj.page)
    }
    let targetUrl = environment.apiUrl + `inquiries/provider?service_provider=${reqObj.providerId}&serviceprovider_branch=${reqObj.serviceprovider_branch}`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getInquiriesDetails = (providerId: any) => {
    let targetUrl = environment.apiUrl + `inquiries/detail/${providerId}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getExpenseList = (reqObj: any) => {
    let httpParams = new HttpParams()
    if (reqObj.startDate !== undefined) {
      httpParams = httpParams.append('start', reqObj.startDate)
    }

    if (reqObj.endDate !== undefined) {
      httpParams = httpParams.append('end', reqObj.endDate)
    }
    if (reqObj.page !== undefined && reqObj.page !== '') {
      httpParams = httpParams.append('page', reqObj.page)
    }
    let targetUrl = environment.apiUrl + `expense/list`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getExpenseCategory = () => {
    let targetUrl = environment.apiUrl + `expense/category/autocomplete`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  addRecordExpenses = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `expense/create`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getExpenseDetails = (expenseId: any) => {
    let targetUrl = environment.apiUrl + `expense/details/${expenseId}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  updateRecordExpenses = (expenseId: any) => {
    let targetUrl = environment.apiUrl + `expense/update/`;
    return this.http.put<[]>(targetUrl, expenseId).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  // Update Expense Record
  // updateRecordExpenses(expenseId: any) {
  //   let targetUrl = environment.apiUrl + `expense/update/`;
  //   let payload={
  //     expense_id: expenseId,
  //     purpose_of_expense: expenseId,
  //     amount: expenseId,
  //     expense_date: expenseId
  //   }
  //     return this.http.put<any>(targetUrl, payload).pipe(
  //     map(response => {
  //       return response
  //     }),
  //     catchError(err => {
  //       return of(err);
  //     }))
  // }

  addCart = (reqObj: any) => {
    let targetUrl = environment.apiUrl + 'customer/add_cart';
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getTotalCustomerList = () => {
    let targetUrl = environment.apiUrl + `customer/totallist`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getCustomerAddressList = (customerId: any) => {
    let targetUrl = environment.apiUrl + `customer/${customerId}/address/list`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getServiceReqStaffList = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `employee/staff/by_branch/${reqObj.branchId}?service_id=${reqObj.serviceId}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getAvailableTimeSlots = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `booking/available_slots?branch_id=${reqObj.branchId}&booking_date=${reqObj.bookingDate}&lead_staff=${reqObj.staffId}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  createServiceRequestWithExitingCustomer = (reqObj: any) => {
    let targetUrl = environment.apiUrl + 'booking/by_provider';
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  createServiceRequestWithNewCustomer = (reqObj: any) => {
    let targetUrl = environment.apiUrl + 'booking/by_provider';
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getServiceRequestList = (reqObj: any) => {
    let httpParams = new HttpParams()
    if (reqObj.status !== undefined) {
      httpParams = httpParams.append('status', reqObj.status)

    }

    if (reqObj.startDate !== undefined) {
      httpParams = httpParams.append('start', reqObj.startDate)
    }

    if (reqObj.endDate !== undefined) {
      httpParams = httpParams.append('end', reqObj.endDate)
    }
    if (reqObj.page !== undefined && reqObj.page !== '') {
      httpParams = httpParams.append('page', reqObj.page)
    }
    let targetUrl = environment.apiUrl + `booking/list/by_branch/${reqObj.branchId}`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getServiceRequestCount = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `servicerequest/count/by_branch?branch_id=${reqObj.branchId}&status=${reqObj.status}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getServiceRequestDetails = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `booking/${reqObj.service_request_id}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  updateServiceRequest = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `booking/update`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getStaffAvailability = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `booking/staffavailability?subrequest_id=${reqObj.subrequest_id}`
    // &gender=${reqObj.gender}&speciality=${reqObj.speciality};
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  assignStaff = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `booking/assign_staff`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  onServiceRequestStatusChange = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `booking/status_change`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  serviceRequestAcceptReject = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `servicerequest/accept_reject`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  createCustomerAddress = (reqObj: any) => {
    let targetUrl = environment.apiUrl + 'customer/address';
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getProfileDetails = (employeeId: any) => {
    let targetUrl = environment.apiUrl + `employee/${employeeId}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getServiceRequestScheduleList = (reqObj: any) => {
    let httpParams = new HttpParams()
    // if (reqObj.providerId !== undefined) {
    //   httpParams = httpParams.append('service_provider_id', reqObj.providerId)

    // }
    if (reqObj.branchId !== undefined) {
      httpParams = httpParams.append('branch_id', reqObj.branchId)

    }

    if (reqObj.startDate !== undefined) {
      httpParams = httpParams.append('start', reqObj.startDate)
    }

    let targetUrl = environment.apiUrl + `booking/scheduled`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  providerUploadDocuments = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `serviceprovider/upload/documents`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  handleError(error: any) {
    let errorMessage: any;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = {
        errorCode: `${error.error.status_code}`,
        message: `${error.error.message}`
      }

    }
    console.log(errorMessage);
    return of(errorMessage);
  }



  getCustomerInvoice = (reqobj: any) => {
    let targetUrl = environment.apiUrl + 'invoice/by_service_request';
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  getUsersList = (reqObj: any) => {
    let httpParams = new HttpParams()
    if (reqObj.role !== undefined) {
      httpParams = httpParams.append('role', reqObj.role)

    }
    if (reqObj.status !== undefined && reqObj.status !== '') {
      httpParams = httpParams.append('status', reqObj.status)
    }
    if (reqObj.query !== undefined && reqObj.query !== '') {
      httpParams = httpParams.append('query', reqObj.query)
    }
    if (reqObj.page !== undefined && reqObj.page !== '') {
      httpParams = httpParams.append('page', reqObj.page)
    }

    let targetUrl = environment.apiUrl + 'serviceprovider/employees';
    return this.http.get<[]>(targetUrl, {
      params: httpParams
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getUserDetails = (empId: any) => {
    let targetUrl = environment.apiUrl + `employee/${empId}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getStaffJobsList = (reqObj: any) => {
    let httpParams = new HttpParams()
    if (reqObj.staffId !== undefined) {
      httpParams = httpParams.append('staff_id', reqObj.staffId)
    }
    if (reqObj.status !== undefined && reqObj.status !== '') {
      httpParams = httpParams.append('status', reqObj.status)
    }
    if (reqObj.query !== undefined && reqObj.query !== '') {
      httpParams = httpParams.append('query', reqObj.query)
    }
    if (reqObj.page !== undefined && reqObj.page !== '') {
      httpParams = httpParams.append('page', reqObj.page)
    }
    if (reqObj.startDate !== undefined) {
      httpParams = httpParams.append('start', reqObj.startDate)
    }

    if (reqObj.endDate !== undefined) {
      httpParams = httpParams.append('end', reqObj.endDate)
    } 
    let targetUrl = environment.apiUrl + 'booking/subrequestlist_bystaff';
    return this.http.get<[]>(targetUrl, {
      params: httpParams
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  changePassword = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `auth/changepassword`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  updateWeekSchedule = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/update_week_schedule`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  addBranchImages = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/add_images`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  deleteBranchImages = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/delete_images`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getHolidayList = (reqObj: any) => {
    let httpParams = new HttpParams()
    if (reqObj.query !== undefined && reqObj.query !== '') {
      httpParams = httpParams.append('query', reqObj.query)
    }
    if (reqObj.page !== undefined && reqObj.page !== '') {
      httpParams = httpParams.append('page', reqObj.page)
    }
    let targetUrl = environment.apiUrl + `branch/holidaylist/${reqObj.branchId}`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  createHoliday = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/create_holiday`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  updateHoliday = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/update_holiday`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  deleteHoliday = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/delete_holiday`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  onServiceRequest_accept_reject = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `booking/accept_reject`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  
  cancelSelectedServices = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `booking/cancel_selected_services`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  createInvoice = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `transaction/stripe/create_invoice`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  checkoutSession = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `transaction/stripe/checkout_session`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  deleteTotalCart = () => {
    let targetUrl = environment.apiUrl + 'customer/delete_totalcart';
    return this.http.put<[]>(targetUrl, {}).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  getPlanList = (reqObj: any) => {
    let httpParams = new HttpParams();
    if (reqObj.query !== undefined && reqObj.query !== '') {
      httpParams = httpParams.append('query', reqObj.query)
    }
    if (reqObj.page !== undefined && reqObj.page !== '') {
      httpParams = httpParams.append('page', reqObj.page)
    }
    let targetUrl = environment.apiUrl + `service/recurring_plan_list/${reqObj.branchId}`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams
    }).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  createPlan = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `service/create_recurring_plan`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  updatePlan = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `service/update_recurring_plan`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  deletePlan = (planId: any) => {
    let targetUrl = environment.apiUrl + `service/delete_recurring_plan/${planId}`;
    return this.http.put<[]>(targetUrl, {planId: planId}).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  getplanDetails = (planId: any) => {
    let targetUrl = environment.apiUrl + `service/recurring_plan/${planId}`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  createRecurringByProvider = (reqObj: any) => {
    let targetUrl = environment.apiUrl + 'booking/recurring_by_provider';
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  updateRecurringByProvider = (reqObj: any) => {
    let targetUrl = environment.apiUrl + 'booking/update_recurring';
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  cancelRecurringByProvider = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `booking/cancel_recurring`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  onPlanStatusChange = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `service/recurring_plan_statuschange/${reqObj.planId}`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  updateInquiry  = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `inquiries/update/`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getWeekSchedule = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/week_schedule/${reqObj.branchId}`;
    return this.http.get<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  getBranchReviews = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `branch/${reqObj.branchId}/reviews`;
    return this.http.get<[]>(targetUrl).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  addComment  = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `booking/add_comment_for_review`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  ratingStatusChange  = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `booking/rating_status_change`;
    return this.http.put<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  deleteReview = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `booking/delete_rating`;
    return this.http.put<[]>(targetUrl, {review_id: reqObj.review_id}).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return throwError(err);
      }))
  }

  saveDeposit  = (reqObj: any) => {
    let targetUrl = environment.apiUrl + `serviceprovider/settings`;
    return this.http.post<[]>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }

  public downloadPdf(reqObj: any): any {
    let url=  environment.apiUrl + `invoice/by_service_request`
    let httpParams = new HttpParams();
    let headers: any = new Headers();
    headers= headers.append('content-type', 'application/pdf');

    if (reqObj.service_request !== undefined && reqObj.service_request !== '') {
      httpParams = httpParams.append('service_request', reqObj.service_request)
    }

    headers.append('Authorization', 'JWT ' + localStorage.getItem('id_token'));
    // return this.http.get(url,{  
    //   params: httpParams,
    //   headers: headers,

    //   responseType: ResponseContentType.Blob }).map(
    //     (res) => {
    //         return new Blob([res.blob()], { type: 'application/pdf' })
    //     })


        // return this.http.get(url, { headers: new HttpHeaders({
        //   'Authorization': 'Basic ' + encodedAuth,
        //   'Content-Type': 'application/octet-stream',
        //   }), responseType: 'blob'}).pipe (
        //   tap (
        //     // Log the result or error
        //     data => console.log('You received data'),
        //     error => console.log(error)
        //   )
        //  );
        // }
}

 
  invoiceServiceRequest = (reqObj: any) => {
    let httpParams = new HttpParams();
    
    let headers = new HttpHeaders();
    headers= headers.append('content-type', 'application/pdf');

    if (reqObj.service_request !== undefined && reqObj.service_request !== '') {
      httpParams = httpParams.append('service_request', reqObj.service_request)
    }
    let targetUrl = environment.apiUrl + `invoice/by_service_request`;
    return this.http.get<[]>(targetUrl, {
      params: httpParams,
      headers: headers,
      // responseType: 'arraybuffer'
    }).pipe(map((data: any) => {

      let blob = new Blob([data], {
          type: 'application/pdf' // must match the Accept type
          // type: 'application/octet-stream' // for excel 
      });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'samplePDFFile.pdf';
      link.click();
      window.URL.revokeObjectURL(link.href);

  }),
    // .pipe(
      // map(response => {
      //   return response
      // }),
      catchError(err => {
        console.log(err)
        return of(err);
      }))
  }

  updateProviderAddress(reqObj: any) {
    let targetUrl = environment.apiUrl + `serviceprovider/address/update`;
    return this.http.put<any>(targetUrl, reqObj).pipe(
      map(response => {
        return response
      }),
      catchError(err => {
        return of(err);
      }))
  }


  

}

