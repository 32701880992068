<div class="container" id="main-page-wrapper">

    <h3>Subscription</h3>
    <hr>
    <div class="sub_wrapper">
        <div class="left_wrapper">

            <p class="auto-plan">Current Plan : BASIC</p>
            <div class="alert">
                <div class="alert_msg">Your Plan Expired,Please Renewal.</div>
                <div id="toggle_btn">
                    <p-inputSwitch [(ngModel)]="checked2"></p-inputSwitch>
                </div>
            </div>
        </div>

        <div class="right_Wrapper">
            <h4>Renewal and Next Payment</h4>

            <p>Your subscription has expired on <b>July 27, 2022.</b> We recommend you to renewal before your plan
                expire and avoid interrupt in utilizing our platform</p>
            <h4>Payment History</h4>
            <p>Last Payment :<b> June 27, 2022 </b></p>
            <a href="#">View Invoice</a>
        </div>
    </div>
</div>

<div class="container" id="plan_wrapper">
    <div class="frst_plan_wrap">
<div class="row">
<div class="col-md-6 col-6">
        <h1>Basic</h1>
    </div>
    <div class="col-md-6 col-6">
        <p class="p_on">You are on</p>
    </div>
</div>
        <div class="row">
            <div class="col-md-6">
                <p class="b-date">unlimited Usage and Advanced Features</p>
                <h3 class="p-year">299GB/Per year</h3>
            </div>
            <div class="col-md-6">
                <div class="of-inner">
                    <select class="form-select" aria-label="Default select example">
                        <option value="Today" selected>Monthly</option>
                        <option value="Yesterday">Annual</option>
                    </select>
                </div>
            </div>
        </div>
      
     <hr>
<div class="row mt-3">
    <div class="col-md-6 col-6">
    <p>Valid From: &nbsp; NA</p>
</div>
 <div class="col-md-6 col-6">
  <p>Valid To: &nbsp;NA</p>
 </div>
    </div>
       
    <div class="row mt-3">
        <div class="col-md-12">
            <button class="save-btn-text" type="submit">Renewal</button>
        </div>
    </div>
    
    </div>
    
    <div class="frst_plan_wrap">
        <h1>Standard</h1>
        <div class="row">
            <div class="col-md-6">
                <p class="b-date">unlimited Usage and Advanced Features</p>
                <h3 class="p-year">299GB/Per year</h3>
            </div>
            <div class="col-md-6">
                <div class="of-inner">
                    <select class="form-select" aria-label="Default select example">
                        <option value="Today" selected>Monthly</option>
                        <option value="Yesterday">Annual</option>
                    </select>
                </div>
            </div>
        </div>
      
     <hr>
<div class="row mt-3">
    <div class="col-md-6 col-6">
    <p>Valid From: &nbsp; NA</p>
</div>
 <div class="col-md-6 col-6">
  <p>Valid To: &nbsp;NA</p>
 </div>
    </div>
       
    <div class="row mt-3">
        <div class="col-md-12">
            <button class="save-btn-text" type="submit">Upgrade</button>
        </div>
    </div>
    
    </div>
    <div class="frst_plan_wrap">
        <h1>Premium</h1>
        <div class="row">
            <div class="col-md-6">
                <p class="b-date">unlimited Usage and Advanced Features</p>
                <h3 class="p-year">299GB/Per year</h3>
            </div>
            <div class="col-md-6">
                <div class="of-inner">
                    <select class="form-select" aria-label="Default select example">
                        <option value="Today" selected>Monthly</option>
                        <option value="Yesterday">Annual</option>
                    </select>
                </div>
            </div>
        </div>
      
     <hr>
<div class="row mt-3">
    <div class="col-md-6 col-6">
    <p>Valid From: &nbsp; NA</p>
</div>
 <div class="col-md-6 col-6">
  <p>Valid To: &nbsp;NA</p>
 </div>
    </div>
       
    <div class="row mt-3">
        <div class="col-md-12">
            <button class="save-btn-text" type="submit">Upgrade</button>
        </div>
    </div>
    
    </div>

</div>