import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-branch-shedule',
  templateUrl: './branch-shedule.component.html',
  styleUrls: ['./branch-shedule.component.scss']
})
export class BranchSheduleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
 
  }
  mytime: Date = new Date();
}
