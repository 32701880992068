import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs';
import { StorageService } from './services/storage.service';

declare var jQuery: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'booking-platform-bos';
  currentRoute: any;

  constructor(private router: Router, private modalService: NgbModal, private storageService: StorageService) {
    this.router.events.pipe(filter((event: any) => event instanceof NavigationStart)).subscribe(
      (event: any) => {
        this.currentRoute = event.url;
        jQuery('body, html').animate({ scrollTop: 0 }, 100)
      }
    );
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(
      (event: any) => {
        this.currentRoute = event.url;
        jQuery('body, html').animate({ scrollTop: 0 }, 100)
      }
    );
    
    let userObj = JSON.parse(<string>this.storageService.getItem("user_info"));
    if (userObj != null && userObj.branches) {
      const branchList = userObj.branches;
      const branch_id = JSON.parse(<string>this.storageService.getItem("branchId"));
      if (JSON.parse(<string>this.storageService.getItem("user_info")).role === 'Branch Manager') {
        if (branchList.length > 1 && branch_id === undefined || branch_id === null) {
            this.router.navigate(['/branch-selection']);
        }
      }
    }
    
  }


}
