import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DropdownModule } from 'primeng/dropdown';
import { SharedModule } from './shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HeaderComponent } from './components/header/header.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { ServiceRequestComponent } from './components/service-request/service-request.component';
import { InprogressRequestComponent } from './components/inprogress-request/inprogress-request.component';
import { CreateNewServiceComponent } from './components/create-new-service/create-new-service.component';
import { ViewServicesComponent } from './components/view-services/view-services.component';
import { CreateServiceComponent } from './components/create-service/create-service.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BranchSheduleComponent } from './components/branch-shedule/branch-shedule.component';
import { AddBranchComponent } from './components/add-branch/add-branch.component';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { SalesReportsComponent } from './components/sales-reports/sales-reports.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FormsModule } from '@angular/forms';
import { CalendarModule} from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule} from 'primeng/inputtext';
import { InputSwitchModule} from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton'
import { SubscriptionComponent } from './subscription/subscription.component';
import { RegisterYourBusinessComponent } from './components/register-your-business/register-your-business.component';
import {MessagesModule} from 'primeng/messages';
import {CheckboxModule} from 'primeng/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { PricingPageComponent } from './components/pricing-page/pricing-page.component';
import {TabViewModule} from 'primeng/tabview';
import {AccordionModule} from 'primeng/accordion';
import { PasswordModule } from "primeng/password";
import { ButtonModule } from 'primeng/button';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastModule } from 'primeng/toast';
import { CarouselModule } from 'primeng/carousel';
import { SidebarModule } from 'primeng/sidebar';
import { ManageInventoryComponent } from './manage-inventory/manage-inventory.component';
import { PointOfSaleComponent } from './point-of-sale/point-of-sale.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailComponent } from './payment-fail/payment-fail.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideMenuComponent,
    BreadcrumbComponent,
    HomeComponent,
    FooterComponent,
    ServiceRequestComponent,
    InprogressRequestComponent,
    CreateNewServiceComponent,
    ViewServicesComponent,
    CreateServiceComponent,
    ForgotPasswordComponent,
    SignInComponent,
    BranchSheduleComponent,
    AddBranchComponent,
    SalesReportsComponent,
    DashboardComponent,
    SubscriptionComponent,
    RegisterYourBusinessComponent,
    ChangePasswordComponent,
    UpdatePasswordComponent,
    PricingPageComponent,
    ManageInventoryComponent,
    PointOfSaleComponent,
    PaymentSuccessComponent,
    PaymentFailComponent,





   

  ],
  imports: [
    BrowserModule,
    SharedModule,
    CommonModule,
    AppRoutingModule,
    DropdownModule,
    BrowserAnimationsModule,
    NgChartsModule,
    PaginationModule,
    BsDatepickerModule,
    FormsModule,
    CalendarModule,
    DialogModule,
    InputTextModule,
    InputSwitchModule,
    RadioButtonModule,
    CheckboxModule,
    MessagesModule,
    ReactiveFormsModule,
    TabViewModule,
    AccordionModule,
    PasswordModule,
    ButtonModule,
    NgbModule,
    ToastModule,
    CarouselModule,
    SidebarModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleAPIKey,
      libraries: ["places"],
      language: 'en'
    }),
   
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}