<!-- <div class="bg-white" id="sidebar-wrapper">
    <div class="sidebar-heading">
        <img src="{{siteLogo}}" alt="site logo" class="header-logo" [routerLink]="'/home'" />
    </div>
    <div class="list-group list-group-flush mt-4" id="side-menu">
        <ul class="sidebar-list">
            <li *ngFor="let menu of menuList; let last= last;" class="nav-menu">
                <a data-bs-toggle="collapse" [routerLink]="menu.link" *ngIf="menu.child.length === 0">
                    <img [src]="menu.icon" alt="">&nbsp;&nbsp; <span
                        [ngClass]="{'active-link' : currentRoute === '/'+menu.link }">{{menu.name}}</span>
                    <i class="bi small bi-caret-down-fill"></i>
                </a>
                <a data-bs-toggle="collapse" [attr.data-bs-target]="'#' + menu.id" href="javascript:void(0)"
                    *ngIf="menu.child.length > 0">
                    <img [src]="menu.icon" alt="">&nbsp;&nbsp;<span
                        [ngClass]="{'active-link' : currentRoute?.includes(menu.dlink)}">{{menu.name}}</span>
                    <i class="bi small bi-caret-down-fill"></i>
                </a>
                <ul [id]="menu.id" class="submenu collapse" data-bs-parent="#nav_accordion">
                    <li *ngFor="let cObj of menu.child">
                        <a class="nav-link" [routerLink]="[cObj.link]"
                            [ngClass]="{'active-link' : currentRoute === '/'+cObj.link }">{{cObj.name}}</a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div> -->

<div class="bg-white" id="sidebar-wrapper">
    <div class="sidebar-heading">
        <img src="{{siteLogo}}" alt="site logo" class="header-logo"/>
    </div>
    <div class="list-group list-group-flush mt-4" id="side-menu">
        <ul class="sidebar-list">
            <li *ngFor="let menu of menuList; let last = last;" class="nav-menu" [ngClass]="{'active-link': currentRoute === '/' + menu.link}">
                <a data-bs-toggle="collapse" [routerLink]="menu.link" *ngIf="menu.child.length === 0">
                    <img [src]="menu.icon" alt="">&nbsp;&nbsp;<span>{{menu.name}}</span>
                    <i class="bi small bi-caret-down-fill"></i>
                </a>
                <a data-bs-toggle="collapse" [attr.data-bs-target]="'#' + menu.id" href="javascript:void(0)" *ngIf="menu.child.length > 0">
                    <img [src]="menu.icon" alt="">&nbsp;&nbsp;<span>{{menu.name}}</span>
                    <i class="bi small bi-caret-down-fill"></i>
                </a>
                <ul [id]="menu.id" class="submenu collapse" data-bs-parent="#nav_accordion">
                    <li *ngFor="let cObj of menu.child" [ngClass]="{'active-link': currentRoute === '/' + cObj.link}">
                        <a class="nav-link" [routerLink]="[cObj.link]">{{cObj.name}}</a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>


