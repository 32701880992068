import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { RouteConstants } from 'src/app/shared/route-constants';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(public dataService: DataService, public router: Router, public activatedRoute: ActivatedRoute, public storageService: StorageService) { }
  addBranchForm: any;
  fileName: any;
  myFiles: any = [];
  imgURL: any = '';
  fileList: any[] = [];
  maxFileSize = 0.5 * 1024 * 1024; // modify this to your desired max file size
  branchSlugName: any;
  formSubmited = false;
  showHolidayformSubmited = false;
  pslug_title: any = JSON.parse(<string>this.storageService.getItem("user_info")).provider_slug_title;
  providerId: any = JSON.parse(<string>this.storageService.getItem("user_info")).provider_id;
  branchId: any = JSON.parse(<string>this.storageService.getItem("branchId"));
  timerArray: any = [
    {
      weekName: "Monday",
      isOpen: false,
      openTime: null,
      closingTime: null
    },
    {
      weekName: "Tuesday",
      isOpen: false,
      openTime: null,
      closingTime: null
    },
    {
      weekName: "Wednesday",
      isOpen: false,
      openTime: null,
      closingTime: null
    },
    {
      weekName: "Thursday",
      isOpen: false,
      openTime: null,
      closingTime: null
    },
    {
      weekName: "Friday",
      isOpen: false,
      openTime: null,
      closingTime: null
    },
    {
      weekName: "Saturday",
      isOpen: false,
      openTime: null,
      closingTime: null
    },
    {
      weekName: "Sunday",
      isOpen: false,
      openTime: null,
      closingTime: null
    }

  ]
  tempWeekSchedule: any;
  branchImagesList: any;
  showPreview = false;
  imgPreview: any;
  display: boolean = false;
  searchKeyword:any;
  
  depositAmt: any;
  depositType = 'Percentage'
  role: any = JSON.parse(<string>this.storageService.getItem("user_info"))?.role;
  ngOnInit(): void {


      
      if(JSON.parse(<string>this.storageService.getItem("user_info"))){
        const userInfo =JSON.parse(<string>this.storageService.getItem("user_info"));

        for(var i=0;i<userInfo.branches?.length; i++){
          if(+this.branchId === userInfo.branches[i].branch_id){
            this.branchSlugName = userInfo.branches[i].slug_service_branch_name;
          }
        }
      }

      if(!this.branchSlugName){
        this.branchSlugName = JSON.parse(<string>this.storageService.getItem("user_info")).branch_slug_name
      }

    this.addBranchForm = new FormGroup({
      timings: new FormArray([], [Validators.required])
    });
    this.patch();
    if(this.role !== 'SERVICE_PROVIDER'){
      this.getHolidayList();
      this.getProvidersBranchList(); 
      this.getBranchDetails(); 
    }else if(this.role === 'SERVICE_PROVIDER'){
      this.getProviderDetails()
    }
   
  }
  getBranchDetails = () => {
    const reqObj = {
      branchSlugName: this.branchSlugName,
      providerId: this.providerId
    }
    this.dataService.getBranchDetails(reqObj).subscribe((res: any) => {
      if (res && res.error === undefined) {
        this.formatterArray(JSON.parse(res.week_schedule));
      }
    });
  }
  formatterArray = (timings: any) => {
    let weekList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    let temp = [];
    if (timings?.length !== 0 && timings !== undefined) {
      for (let i = 0; i < weekList.length; i++) {
        temp.push({
          weekName: weekList[i],
          isOpen: timings[weekList[i]].isOpen,
          openTime: timings[weekList[i]].openTime === '00:00' ? null : this.setTime(timings[weekList[i]].openTime),
          closingTime: timings[weekList[i]].closingTime === '00:00' ? null : this.setTime(timings[weekList[i]].closingTime)
        })
      }
      console.log(this.timerArray)
      this.timerArray = temp;
    }
  }

  setTime = (obj: any) => {
    let convertedTime = moment(obj, 'hh:mm A').format('HH:mm')
    const time = new Date();
    time.setHours(+convertedTime.split(':')[0]);
    time.setMinutes(+convertedTime.split(':')[1]);
    return time || null;
  }

  get addBranchFormControls() { return this.addBranchForm.controls; }

  onSubmit = (event: any) => {
    this.formSubmited = true;
    event.preventDefault();

    if (this.addBranchForm.valid) {
      this.refreshWeekSchedule();


      const reqObj: any =
      {
        branch_id: this.branchId,
        week_schedule:this.tempWeekSchedule
      }


      this.dataService.updateWeekSchedule(reqObj).subscribe((res: any) => {
        if (res && res.error === undefined) {
          Swal.fire({
            text: 'Week Schedule updated successfully',
            icon: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((res: any) => {
            if (res.isConfirmed) {
              this.addBranchForm.reset()
              this.gotoSettingsPage();
              this.getBranchDetails();
            }
          });;
        } else if (res && res.error) {
          Swal.fire({
            text: res.error.Error,
            icon: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }
      });

    }
  }


  gotoSettingsPage() {
    this.router.navigate([`${RouteConstants.HOME_PAGE + '/' + RouteConstants.PROFILE + '/' + RouteConstants.SETTINGS}`])
  }

  patch = () => {
    const control = <FormArray>this.addBranchForm.get("timings");
    this.timerArray.forEach((x: any) => {
      control.push(
        new FormGroup({
          isOpen: new FormControl(false),
          openTime: new FormControl(null),
          closingTime: new FormControl(null)
        })
      )
    });
  }

  refreshWeekSchedule = () => {
    let schedule: any = {};
    let list = this.timerArray;
    for (let i = 0; i < list.length; i++) {
      var weekName = this.timerArray[i].weekName;
      switch (weekName) {
        case 'Monday':
          schedule.Monday = {
            'isOpen': list[i].isOpen,
            'openTime': list[i].openTime ? moment(list[i].openTime, "hh:mm a").format('HH:mm') : '00:00',
            'closingTime': list[i].closingTime ? moment(list[i].closingTime, "hh:mm a").format('HH:mm') : '00:00'
          }
          break;
        case 'Tuesday':
          schedule.Tuesday = {
            'isOpen': list[i].isOpen,
            'openTime': list[i].openTime ? moment(list[i].openTime, "hh:mm a").format('HH:mm') : '00:00',
            'closingTime': list[i].closingTime ? moment(list[i].closingTime, "hh:mm a").format('HH:mm') : '00:00'
          }
          break;
        case 'Wednesday':
          schedule.Wednesday = {
            'isOpen': list[i].isOpen,
            'openTime': list[i].openTime ? moment(list[i].openTime, "hh:mm a").format('HH:mm') : '00:00',
            'closingTime': list[i].closingTime ? moment(list[i].closingTime, "hh:mm a").format('HH:mm') : '00:00'
          }
          break;
        case 'Thursday':
          schedule.Thursday = {
            'isOpen': list[i].isOpen,
            'openTime': list[i].openTime ? moment(list[i].openTime, "hh:mm a").format('HH:mm') : '00:00',
            'closingTime': list[i].closingTime ? moment(list[i].closingTime, "hh:mm a").format('HH:mm') : '00:00'
          }
          break;
        case 'Friday':
          schedule.Friday = {
            'isOpen': list[i].isOpen,
            'openTime': list[i].openTime ? moment(list[i].openTime, "hh:mm a").format('HH:mm') : '00:00',
            'closingTime': list[i].closingTime ? moment(list[i].closingTime, "hh:mm a").format('HH:mm') : '00:00'
          }
          break;
        case 'Saturday':
          schedule.Saturday = {
            'isOpen': list[i].isOpen,
            'openTime': list[i].openTime ? moment(list[i].openTime, "hh:mm a").format('HH:mm') : '00:00',
            'closingTime': list[i].closingTime ? moment(list[i].closingTime, "hh:mm a").format('HH:mm') : '00:00'
          }
          break;
        case 'Sunday':
          schedule.Sunday = {
            'isOpen': list[i].isOpen,
            'openTime': list[i].openTime ? moment(list[i].openTime, "hh:mm a").format('HH:mm') : '00:00',
            'closingTime': list[i].closingTime ? moment(list[i].closingTime, "hh:mm a").format('HH:mm') : '00:00'
          }
          break;
      }
    };
    this.tempWeekSchedule = JSON.stringify(schedule, this.escape) //tempWeekSchedule.replace(/\\/g, '')
    this.tempWeekSchedule = this.tempWeekSchedule.toString()
  }

  escape = (key: any, val: any) => {
    if (typeof (val) != "string") return val;
    return val
      .replace(/[\\]/g, '\\\\')
      .replace(/[\/]/g, '\\/')
      .replace(/[\b]/g, '\\b')
      .replace(/[\f]/g, '\\f')
      .replace(/[\n]/g, '\\n')
      .replace(/[\r]/g, '\\r')
      .replace(/[\t]/g, '\\t')
      .replace(/[\"]/g, '\\"')
      .replace(/\\'/g, "\\'");
  }

  onFileChange(event: any) {
    if (event && event.addedFiles.length > 0) {
      if (event.addedFiles[0].size > this.maxFileSize) {
        Swal.fire('', 'Image size should not exceed 2MB');
        return;
      }

      this.fileList = [];
      this.myFiles = [];
      this.fileList = event.addedFiles;
      this.myFiles.push(...event.addedFiles);

      const formData = new FormData();
      for (var i = 0; i < this.myFiles.length; i++) {
        formData.append("image_url", this.myFiles[i]);
      }
      this.dataService.uploadFile(formData).subscribe((res: any) => {
        if (res && res.error === undefined) {
          let data = Object.assign({}, { res });
          this.myFiles = [];

          this.fileName = data.res;
          this.imgURL = environment.mediaApiurl + this.fileName;
          event.addedFiles = [];
        } else if (res && res.error) {
          Swal.fire({
            text: res.error.error,
            icon: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }
      });
    }
  }
  //Method to remove files
  onRemove(event: any) {
    this.fileList.splice(this.fileList.indexOf(event), 1);
    this.imgURL = '';
  }


  onUpload = () =>{
    if(this.branchImagesList?.length > 0 ){
      this.display = true;  
      return
    }
    this.uploadDocuments();
  }

  uploadDocuments = () => {
    if (this.imgURL === '' || this.imgURL === undefined) {
      Swal.fire({
        text: 'Please Upload Image',
        icon: 'warning',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      return
    }
    let reqObj: any =
    {
      branch_id: this.branchId,
      image: this.imgURL
    }
    if (this.imgURL !== '') {
      this.dataService.addBranchImages(reqObj).subscribe((res: any) => {
        if (res && res.error === undefined) {
          Swal.fire({
            text: res.Info,
            icon: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((res: any) => {
            if (res.isConfirmed) {
              this.myFiles = [];
              this.imgURL = '';
              this.fileList = [];
              this.display = false;
              this.getProvidersBranchList();
            }
          });;
        } else if (res && res.error) {
          Swal.fire({
            text: res.error.Error,
            icon: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }
      });
    }

    
  }
  holidayList: any;
  showHolidayList = !false;
  showCreateHoliday = false;
  addHolidayForm: any;
  maxDate = new Date();
  holidayId = '';
  totalCount = 0;
  pageNo =1;
  getHolidayList = () => {
    this.dataService.getHolidayList({ branchId: this.branchId, query: this.searchKeyword, page: this.pageNo }).subscribe((res: any) => {
      if (res && res.error === undefined) {
        this.holidayList = res.results;
        this.totalCount = res.count;
      }
    });
  }

  paginate(event: any) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.pageNo = event.page + 1;
    this.getHolidayList();
}

  gotoCreateHoliday = () => {
    this.showHolidayList = false;
    this.showCreateHoliday = true;
    this.showHolidayformSubmited = false;
    this.holidayId = '';
    this.addHolidayForm = new FormGroup({
      holidayName: new FormControl('', [Validators.required, Validators.maxLength(25)]),
      holidayDate: new FormControl('', [Validators.required]),
    });
  }

  get addHolidayFormControls() { return this.addHolidayForm.controls; }

  onHolidaySubmit = (event: any) => {
    this.showHolidayformSubmited = true;
    event.preventDefault();

    if (this.addHolidayForm.valid) {

      let reqObj: any;
      let apiName: any;

      if (this.holidayId !== '') {
        reqObj = {
          holiday_id: this.holidayId,
          name: this.addHolidayForm.value.holidayName,
          date: moment(new Date(this.addHolidayForm.value.holidayDate)).format('YYYY-MM-DD')
        }
        apiName = this.dataService.updateHoliday(reqObj);
      } else {
        reqObj = {
          branch: this.branchId,
          name: this.addHolidayForm.value.holidayName,
          date: moment(new Date(this.addHolidayForm.value.holidayDate)).format('YYYY-MM-DD')
        }
        apiName = this.dataService.createHoliday(reqObj);
      }


      apiName.subscribe((res: any) => {
        if (res && res.error === undefined) {
          Swal.fire({
            text: this.holidayId === '' ? 'Holiday Created successfully' : 'Holiday updated successfully',
            icon: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((res: any) => {
            if (res.isConfirmed) {
              this.showHolidayList = true;
              this.showCreateHoliday = false;
              this.getHolidayList();
            }
          });;
        } else if (res && res.error) {
          Swal.fire({
            text: res.error.Error,
            icon: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }
      });

    }
  }

  onCancel = () => {
    this.showHolidayList = true;
    this.showCreateHoliday = false;
  }

  deleteHoliday = (id: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete this holiday",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        this.dataService.deleteHoliday({ holiday_id: id }).subscribe((resp: any) => {

          if (resp.success) {
            Swal.fire({
              text: resp.status,
              icon: 'success',
              title: "Your Holiday was  Deleted Successfully",
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((res: any) => {
              if (res.isConfirmed) {
                this.getHolidayList();
              }
            });
          } else {
            Swal.fire({
              text: resp.error,
              icon: 'error',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            });
          }
        });
      }
    });
  }

  gotoEditHoliday = (details: any) => {
   this.gotoCreateHoliday();
    this.holidayId = details.holiday_id;
    this.addHolidayForm.setValue({
      holidayName: details.name,
      holidayDate: new Date(details.date)
    });
  }

  
  getProvidersBranchList = () => {
    this.dataService.getProvidersBranchList({ providerId: this.pslug_title }).subscribe((res: any) => {
      if (res && res.error === undefined) {
        let branchList = res.results;
        for(let  i=0;i<branchList.length;i++){
          if(this.branchId === branchList[i].branch_id){
            this.branchImagesList = branchList[i].images === '' ? [] : branchList[i].images;
          }
        }
      }
    });
    console.log(this.branchImagesList)
  }

  onImageDelete = (data: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete this Image",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        let reqObj: any =
        {
          branch_id: this.branchId,
          image:data
        }
        this.dataService.deleteBranchImages(reqObj).subscribe((res: any) => {
          if (res && res.error === undefined) {
            Swal.fire({
              text: 'Image Deleted Successfuly',
              icon: 'success',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((res: any) => {
              if (res.isConfirmed) {
                this.myFiles = [];
                this.getProvidersBranchList();
              
              }
            });;
          } else if (res && res.error) {
            Swal.fire({
              text: res.error.Error,
              icon: 'error',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        });
      }
    });
  }


  saveDeposit = () => {
    if(this.depositAmt === '' || this.depositAmt === null || this.depositAmt === undefined){
      Swal.fire({
        text: 'Please enter deplosit amount',
        icon: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    }else {
      let reqObj: any = {
        service_provider_id:  JSON.parse(<string>this.storageService.getItem("user_info")).provider_id,
        domain: window.location.hostname,
        deposit_percent: this.depositAmt
      }
      this.dataService.saveDeposit(reqObj).subscribe((res: any) => {
        if (res && res.error === undefined) {
          Swal.fire({
            text: 'Amount updated Successfuly',
            icon: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((res: any) => {
            if (res.isConfirmed) {
              this.myFiles = [];
              this.getProvidersBranchList();
            
            }
          });;
        } else if (res && res.error) {
          Swal.fire({
            text: res.error.Error,
            icon: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }
      });
    }
  }

  getProviderDetails = () => {
    this.dataService.serviceProviderDetails().subscribe((data: any) => {
      if (data) {
        console.log(data);
      }
    });
  }
}

